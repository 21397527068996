import {isCordova, logger} from "../shared/utility";
import {mpoSentry} from "./Sentry";
import queryString from "query-string";
import mobiscroll from "@mobiscroll/react";

export const mpoBranchio = {
    _initted: false,
    _init_attempts: 0,
    _app_props: null,
    _deeplink: null,
    _lastDeepLinkPath: "",
    _lastDeepLinkPathQuery: "",

    Init: function (appProps = null)  {
        logger('mpoBranchio.Init called (props '+(appProps === null ? 'null' : 'set')+')');
        mpoBranchio._init_attempts++;
        if (isCordova()) {
            mpoBranchio._app_props = appProps;
            mpoBranchio._initted = true;
            if (mpoBranchio._app_props != null && mpoBranchio._deeplink !== null) {
                logger('Branchio Init open deeplink');
                let deeplink = mpoBranchio._deeplink;
                mpoBranchio._deeplink = null;
                mpoBranchio.GotoDeeplink(deeplink);
            }
        } else if (process.env.REACT_APP_BRANCH_KEY !== undefined && process.env.REACT_APP_BRANCH_KEY !== '') {
            // mpoBranchio._app_props = appProps;
            /*eslint-disable no-undef*/
            try {
                branch.init(process.env.REACT_APP_BRANCH_KEY, function(err, res) {
                    if (err != null) {
                        logger('Branchio init error');
                        logger(err);
                        mpoSentry.captureException(err);
                    } else {
                        mpoBranchio._initted = true;
                        logger(JSON.stringify(res));
                        //mpoBranchio.GotoDeeplink(res); // not required on web
                    }
                });
            } catch (error) {
                logger('Branchio init exception');
                if (mpoBranchio._init_attempts < 3) {
                    setTimeout(mpoBranchio.Init, 1000, appProps);
                } else {
                    mpoSentry.captureException(error);
                }
            }
            /*eslint-enable no-undef*/
        }
    },

    SetProps: function (appProps = null) {
        logger('Branchio SetProps called (props '+(appProps === null ? 'null' : 'set')+')');
        if (appProps !== null) {
            if (!mpoBranchio._initted) {
                logger('Branchio SetProps init Branchio');
                mpoBranchio.Init(appProps);
            } else {
                mpoBranchio._app_props = appProps;
                if (mpoBranchio._deeplink !== null) {
                    // been awaiting props so we can go to deeplink
                    logger('Branchio SetProps open deeplink');
                    let deeplink = mpoBranchio._deeplink;
                    mpoBranchio._deeplink = null;
                    mpoBranchio.GotoDeeplink(deeplink);
                }
            }
        }
    },

    HandleDeepLinkData: function (appStart = false) {
        if (isCordova()) {
            if (appStart) {
                // delay to allow app to finish loading
                logger('Branchio HandleDeepLinkData -- delay');
                setTimeout(mpoBranchio.HandleDeepLinkData, 1500);
                return;
            }
            try {
                // https://help.branch.io/using-branch/docs/creating-a-deep-link#section-read-deep-links
                logger('Branchio HandleDeepLinkData');
                /*eslint-disable no-undef*/
                // if (cordova.plugins && cordova.plugins.Branch) {
                    // Branch.setDebug(true);
                    // Branch.disableTracking(true); // GDPR
                    Branch.initSession().then(function success(res) {
                        logger(JSON.stringify(res));
                        if (res["+clicked_branch_link"]) {
                            // alert("Open app with a Branch deep link: " + JSON.stringify(res));
                            // Branch quick link: https://cordova.app.link/uJcOH1IFpM
                            // Branch web link: https://cordova-alternate.app.link/uJcOH1IFpM
                            // Branch dynamic link: https://cordova.app.link?tags=one&tags=two&tags=three&channel=Copy&feature=onboarding&stage=new+user&campaign=content+123+launch&type=0&duration=0&source=android&data
                            // Branch uri scheme: branchcordova://open?link_click_id=link-500015444967786346
                            // Branch android intent: intent://open?link_click_id=518106399270344237#Intent;scheme=looprocks;package=com.eneff.branch.cordovatestbed;S.browser_fallback_url=https%3A%2F%2Fcordova.app.link%2FuJcOH1IFpM%3F__branch_flow_type%3Dchrome_deepview%26__branch_flow_id%3D518106399312287278;S.market_referrer=link_click_id-518106399270344237%26utm_source%3DCopy%26utm_campaign%3Dcontent%20123%20launch%26utm_feature%3Donboarding;S.branch_data=%7B%22~feature%22%3A%22onboarding%22%2C%22this_is%22%3A%22true%22%2C%22custom_string%22%3A%22data%22%2C%22testing%22%3A%22123%22%2C%22%24publicly_indexable%22%3A%22false%22%2C%22%24desktop_url%22%3A%22http%3A%2F%2Fwww.example.com%2Fdesktop%22%2C%22%24one_time_use%22%3Afalse%2C%22custom_object%22%3A%22%7B%5C%5C%5C%22random%5C%5C%5C%22%3A%5C%5C%5C%22dictionary%5C%5C%5C%22%7D%22%2C%22~id%22%3A%22517795540654792902%22%2C%22~campaign%22%3A%22content%20123%20launch%22%2C%22%2Bclick_timestamp%22%3A1524764418%2C%22%2Burl%22%3A%22https%3A%2F%2Fcordova.app.link%2FuJcOH1IFpM%22%2C%22custom_boolean%22%3A%22true%22%2C%22custom%22%3A%22data%22%2C%22source%22%3A%22android%22%2C%22%24og_image_url%22%3A%22http%3A%2F%2Florempixel.com%2F400%2F400%2F%22%2C%22%2Bdomain%22%3A%22cordova.app.link%22%2C%22custom_integer%22%3A%221524690301794%22%2C%22~tags%22%3A%5B%22one%22%2C%22two%22%2C%22three%22%5D%2C%22custom_array%22%3A%22%5B1%2C2%2C3%2C4%2C5%5D%22%2C%22~channel%22%3A%22Copy%22%2C%22~creation_source%22%3A2%2C%22%24canonical_identifier%22%3A%22content%2F123%22%2C%22%24og_title%22%3A%22Content%20123%20Title%22%2C%22%24og_description%22%3A%22Content%20123%20Description%201524690296449%22%2C%22%24identity_id%22%3A%22453670943617990547%22%2C%22~stage%22%3A%22new%20user%22%2C%22%2Bclicked_branch_link%22%3Atrue%2C%22%2Bmatch_guaranteed%22%3Atrue%2C%22%2Bis_first_session%22%3Afalse%7D;B.branch_intent=true;end
                            // Branch android app link (device controlled): https://cordova.app.link/uJcOH1IFpM
                            // Branch ios universal link (device controlled): https://cordova.app.link/uJcOH1IFpM
                            mpoBranchio._deeplink = null;
                            mpoBranchio.GotoDeeplink(res);
                        } else if (res["+non_branch_link"]) {
                            logger("Opened app with a non Branch deep link: " + JSON.stringify(res));
                            // Not from Branch, e.g. Firebase Dynamic Link
                            // Could remove Firebase and handle this link ourselves here
                        } else {
                            logger("Opened app organically");
                            // Clicking on app icon or push notification
                        }
                    }).catch(function error(err) {
                        logger('Branchio Cordova initSession error');
                        logger(err, true);
                        mpoSentry.captureException(err);
                    });
                // } else if (mpoBranchio._init_attempts < 5) {
                //     mpoBranchio._init_attempts++;
                //     setTimeout(mpoBranchio.HandleDeepLinkData, 2000);
                // }
                /*eslint-enable no-undef*/
            } catch (error) {
                logger('Branchio Cordova plugin error');
                mpoSentry.captureException(error);
            }
        }
    },

    GotoDeeplink: function(deeplink) {
        logger('Branchio GotoDeeplink called');
        //mobiscroll.toast({message: "GotoDeeplink: "+deeplink, color: 'info'});
        if (deeplink === undefined || deeplink === null) {
            return
        }

        if (!isCordova()) {
            // not needed for web
            mpoBranchio._deeplink = null;
            return;
            // if (deeplink["$desktop_url"]) {
            //     logger('Redirect to desktop url ' + deeplink["$desktop_url"]);
            //     window.location.href = deeplink["$desktop_url"];
            //     return
            // } else if (deeplink["$deeplink_path"]) {
            //     logger('Redirect to deeplink url ' + deeplink["$deeplink_path"]);
            //     window.location.href = deeplink["$deeplink_path"];
            //     return
            // }
            // logger('No desktop or deeplink urls')
        }

        if (mpoBranchio._app_props !== undefined && mpoBranchio._app_props !== null && mpoBranchio._app_props.hasOwnProperty('history')) {
            logger('Branchio GotoDeeplink has props');
            logger('Deeplink: ' + JSON.stringify(deeplink));

            const deeplinkPath = deeplink["$deeplink_path"] ? deeplink["$deeplink_path"] : "";
            if (deeplinkPath === "") {
                logger('Deeplink path empty');
                return;
            }
            const query = deeplinkPath.substring(deeplinkPath.lastIndexOf("?"));
            const params = queryString.parse(query);
            const menuName = params.menu ? params.menu : null;
            const otherParamAction = params.act ? params.act : null;

            let pathName = '';

            if (menuName) {
                pathName = "/menu/"+menuName;
            } else if (otherParamAction) {
                switch (otherParamAction) {
                    case 'acct':
                    case 'account':
                        pathName = '/account'
                        break;
                    case 'hist':
                    case 'history':
                    case 'orders':
                        pathName = '/account/orders'
                        break;
                    case 'rewards':
                        pathName = '/account/rewards'
                        break;
                    case 'cards':
                    case 'billing':
                    case 'managecard':
                    case 'withdraw':
                    case 'crypto':
                    case 'applepay':
                    case 'googlepay':
                        pathName = '/account/managecard'
                        break;
                    case 'addcard':
                        pathName = '/account/addcard'
                        break;
                    case 'topup':
                        pathName = '/account/topup'
                        break;
                    case 'feedback':
                        pathName = '/account/feedback'
                        break;
                    case 'reset':
                        pathName = '/account/reset'
                        break;
                    default:
                }
            }

            if (pathName !== '') {
                logger('Branchio GotoDeeplink '+mpoBranchio._app_props.location.pathname+' --> '+pathName);
                if (pathName+query === mpoBranchio._lastDeepLinkPathQuery) {
                    // prevent page from continuously reloading
                    logger('Branchio GotoDeeplink already at path+query');
                    mpoBranchio._lastDeepLinkPathQuery = ""; // only block once
                    return;
                }
                // logger(JSON.stringify(mpoBranchio._app_props));
                mpoBranchio._lastDeepLinkPathQuery = pathName+query;
                let location = {
                    pathname: pathName,
                    search: query,
                    state: {fromDeeplink: true, fromReload: false}
                }
                if (mpoBranchio._app_props.location.pathname === pathName) {
                    // screen can go blank whilst window reloads
                    if (mpoBranchio._app_props.location.pathname === mpoBranchio._lastDeepLinkPath) {
                        // already done, so ignore to prevent infinite loop
                        logger('Branchio GotoDeeplink already verified path');
                        mpoBranchio._lastDeepLinkPath = "";
                        return;
                    }
                    if (mpoBranchio._app_props.location.hasOwnProperty('state') && mpoBranchio._app_props.location.state !== undefined &&
                        mpoBranchio._app_props.location.state.hasOwnProperty('fromReload') && mpoBranchio._app_props.location.state.fromReload !== undefined &&
                        mpoBranchio._app_props.location.state.fromReload) {
                        // already done, so ignore to prevent infinite loop
                        logger('Branchio GotoDeeplink already verified reload');
                        return;
                    }
                    mpoBranchio._lastDeepLinkPath = mpoBranchio._app_props.location.pathname;
                    mobiscroll.notification.dismiss();
                    mobiscroll.toast({message: "Verifying link...", duration: 2000, display: 'center', color: 'info'});
                    location.state.fromReload = true;
                    mpoBranchio._app_props.history.replace(location);
                    logger('Branchio GotoDeeplink history replace');
                    if (typeof window !== "undefined") {
                        window.location.reload(); // force re-render; todo: could update redux props directly instead
                    } else {
                        logger('Branchio GotoDeeplink window undefined');
                    }
                } else {
                    logger('Branchio GotoDeeplink history push');
                    mpoBranchio._app_props.history.push(location);
                }
            } else {
                logger('Branchio GotoDeeplink no path');
            }
        } else {
            logger('Branchio GotoDeeplink store deeplink and wait for props');
            mpoBranchio._deeplink = deeplink;
        }
    }
}