import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    customer: {
        id: 0,
        name: "",
        email: "",
        mobile: "",
        gender: "",
        birthday: "",
        balance: 0.00,
        balance_held: 0.00,
        balance_debit: 0.00,
        balance_transferable: 0.00,
        status: 0,
        timezone: "",
        timezone_state: "",
        country_code: "",
        brand_id: 0,
        merchant_guid: "",
        menu_name: "",
        billing_id: 0,
        payment_gateway_id: 0,
        credit_card_type: "",
        credit_card_ending: "",
        credit_card_notice: "",
        credit_card_update: 0,
        credit_card_expired: 0,
        auto_topup_amount: 0.00,
        has_saved_card: false,
        has_mobile: false,
        has_mobile_pin: false,
        has_temp_mobile_pin: false,
        is_facebook_connected: false,
        is_apple_connected: false,
        is_google_connected: false,
        billing_addresses: [],
        delivery_addresses: []
    },
    guest: {
        name: "",
        email: "",
        mobile: "",
    },
    checkout: {
        name_new: "",
        mobile_new: "",
        card_name: "",
        card_number: "",
        card_expiry: "",
        card_expiry_date: "",
        card_csc: "",
        save_card: true,
        auto_topup: false,
        auto_topup_amount: 0,
        billing_address_id: 0,
        billing_address: "",
        billing_address1: "",
        billing_address2: "",
        billing_city: "",
        billing_state: "",
        billing_postcode: "",
        billing_country: "",
        billing_geo: {},
        billing_latlng: {},
        delivery_address_id: 0,
        delivery_address: "",
        delivery_address1: "",
        delivery_address2: "",
        delivery_city: "",
        delivery_state: "",
        delivery_postcode: "",
        delivery_country: "",
        delivery_geo: {},
        delivery_latlng: {},
        delivery_validated: false,
        delivery_postcode_id: 0,
        student_name: "",
        store_class_id: "",
        contactless: false,
        custom_data: []
    },
    auth: {
        isLoggedIn: false,
        isMember: false,
        isGuest: true,
        isKiosk: false,
        isStaff: false,
        isTest: false,
        login: {
            method: "",
            value: "",
            token: ""
        }
    },
    eftpos: {
        is_eftpos_enabled: 0
    }
};

const setCustomer = ( state, action ) => {
    // data transformations here
    const updatedState = {
        customer: initialState.customer
    }
    if (Object.keys(action.payload).length === 0 || action.payload.id === 0) {
        action.payload = initialState.customer;
    } else if (Object.keys(state.customer).length < Object.keys(initialState.customer).length) {
        action.payload = updateObject( initialState.customer, action.payload );
    }
    //console.log(action.payload);
    updatedState.customer = action.payload; 
    return updateObject( state, updatedState );
};

const setGuest = ( state, action ) => {
    // data transformations here
    const updatedState = {
        guest: initialState.guest
    }
    if (Object.keys(action.payload).length === 0) {
        action.payload = initialState.guest;
    } else if (Object.keys(state.guest).length < Object.keys(initialState.guest).length) {
        action.payload = updateObject( initialState.guest, action.payload );
    }
    //console.log(action.payload);
    updatedState.guest = action.payload;
    return updateObject( state, updatedState );
};

const setAuth = ( state, action ) => {
    // data transformations here
    if (Object.keys(action.payload).length === 0) {
        action.payload = initialState.auth;
    } else {
        action.payload = updateObject( state.auth, action.payload );
    }
    
    const updatedState = {
        auth: action.payload
    }
    //console.log('auth', updatedState);
    return updateObject( state, updatedState );
};

const setEftpos = ( state, action ) => {
    // data transformations here
    if (Object.keys(action.payload).length === 0) {
        action.payload = initialState.eftpos;
    } else {
        action.payload = updateObject( state.eftpos, action.payload );
    }

    const updatedState = {
        eftpos: action.payload
    }
    //console.log('auth', updatedState);
    return updateObject( state, updatedState );
};

const setCheckout = ( state, action ) => {
    // data transformations here
    if (Object.keys(action.payload).length === 0) {
        action.payload = initialState.checkout;
    }
    const updatedState = {
        checkout: action.payload
    }
    return updateObject( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_CUSTOMER: return setCustomer( state, action );
        case actionTypes.SET_GUEST: return setGuest( state, action );
        case actionTypes.SET_AUTH: return setAuth( state, action );
        case actionTypes.SET_EFTPOS: return setEftpos( state, action );
        case actionTypes.SET_CHECKOUT: return setCheckout( state, action );
        default: return state;
    }
};

export default reducer;