import React, { Component } from 'react';
//import { logger } from '../../shared/utility';

class OrderProductItem extends Component {

    render = () => {
        //logger(this.props);

        return (
            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                <span className="mbsc-txt-s">{this.props.item.short_desc} x {this.props.item.quantity}</span>
                <span className="mbsc-txt-s mbsc-pull-right">{this.props.currencySign}{parseFloat(this.props.item.price).toFixed(2)}</span>
                <span className="mbsc-desc">{this.props.item.notes}</span>
            </div>
        );
    }
}

export default OrderProductItem;