import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import "core-js/es";
import React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { save, load } from "redux-localstorage-simple"
import { logger, isCordova } from './shared/utility';
import { mpoBranchio } from "./lib/Branchio";
import { mpoFirebase } from "./lib/Firebase";
import mobiscroll from "@mobiscroll/react";

import './index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import merchantsReducer from './store/reducers/merchants';
import menuReducer from './store/reducers/menu';
import cartReducer from './store/reducers/cart';
import userReducer from './store/reducers/user';
//import mobiscroll from "@mobiscroll/react";

import { GoogleOAuthProvider } from '@react-oauth/google';

// todo: https://docs.sentry.io/platforms/javascript/guides/react/sourcemaps/uploading/webpack/
Sentry.init({
    dsn: "https://7df94fabcfb94494a28e01ab4d1d7174@o157253.ingest.sentry.io/1482875",
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_APP_VERSION,
    // ignoreErrors: [
    //     'Non-Error exception captured'
    // ],
    // tracesSampleRate: 1.0,
});

Sentry.configureScope(scope => {
    scope.setTag("appId", process.env.REACT_APP_APP_ID);
    scope.setTag("appVersion", process.env.REACT_APP_APP_VERSION);
    scope.setTag("apiVersion", process.env.REACT_APP_API_VERSION);
});

// redux state
const rootReducer = combineReducers({
    merchants: merchantsReducer,
    menu: menuReducer,
    cart: cartReducer,
    user: userReducer
});

const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const createStoreWithMiddleware 
    = composeEnhancers(applyMiddleware(
        thunk, // write action creators that return a function instead of an action
        save() // save redux state to local storage
    ))(createStore);

// signin with google
const isGoogleSigninWebEnabled = !isCordova() && process.env.REACT_APP_GOOGLE_SIGNIN_ENABLED === 'true';
const googleSigninClientId = isGoogleSigninWebEnabled && process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID ? process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID : "";

const store = createStoreWithMiddleware(
    rootReducer,    
    load() // load redux state from local storage
)

const app = isGoogleSigninWebEnabled && googleSigninClientId !== "" ? (
    <Provider store={store}>
        <HashRouter>
            <GoogleOAuthProvider clientId={googleSigninClientId}>
                <App />
            </GoogleOAuthProvider>
        </HashRouter>
    </Provider>
) : (
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>
);

const appRoot = document.getElementById('root');
appRoot.setAttribute('notranslate', true);


// const onResume = () => {
//     // fires when the native platform pulls the application out from the background
//     mobiscroll.toast({message: 'onResume', color: 'info'});
//     /*eslint-disable no-undef*/
//     cordova.plugins.firebase.dynamiclinks.getDynamicLink().then(function(data) {
//         if (data) {
//             mobiscroll.toast({message: "Read dynamic link data on app resume:", color: 'info'});
//         } else {
//             mobiscroll.toast({message: "App wasn't resumed from a dynamic link", color: 'info'});
//         }
//     });
//     /*eslint-enable no-undef*/
// }

const renderApp = () => {
    if (isCordova()) {
        try {
            // cordova is now fully loaded and safe to use device APIs
            /*eslint-disable no-undef*/
            window.open = cordova.InAppBrowser.open;
            /*
            // https://github.com/apache/cordova-ios/blob/master/guides/Cordova%20Custom%20URL%20Scheme%20Handling.md
            window.handleOpenURL = (url) => {
                setTimeout(() => {
                    mobiscroll.toast({message: "handle: "+url, color: 'info'});
                    //alert("received url: " + url);
                }, 0);
            };
            */
            /*
            cordova.plugins.firebase.dynamiclinks.onDynamicLink(function(data) {
                //mobiscroll.toast({message: "link: "+JSON.stringify(data), color: 'info'});
                logger(JSON.stringify(data));
                // data.deeplink: url
            });
            */

            if (mobiscroll.platform.name === 'ios' && typeof StatusBar === 'object') {
                StatusBar.show();
            }
            /*eslint-enable no-undef*/
        } catch (error) {
            logger("renderApp deviceready error");
            logger(error);
        }

        mpoBranchio.Init();
        // mpoBranchio.HandleDeepLinkData();

        //document.addEventListener("pause", onPause, false);
        document.addEventListener('resume', resumeApp, false);
    } else {
        mpoBranchio.Init();
    }
    mpoFirebase.Init();
    ReactDOM.render(app, appRoot, () => {
        logger('rendered app');
        if (isCordova()) {
            mpoBranchio.HandleDeepLinkData(true);
        }
    });
    // registerServiceWorker();
}

const resumeApp = () => {
    logger('resumed app');
    mpoBranchio.HandleDeepLinkData(false);
}

if (isCordova()) {
    // wait for cordova to fully load
    logger('render waiting for deviceready');
    document.addEventListener('deviceready', renderApp, false);
} else {
    logger('render immediately');
    renderApp();
}