import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    slug: "",
    merchant: {},
    menuItems: [],
    fulfilmentType: {},
    fulfilmentOptions: {},
    order: {
        num_items: 0
    }
};

const setSlug = ( state, action ) => {
    const updatedState = {
        slug: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchant = ( state, action ) => {
    // could add logic to manipulate payload here
    const updatedState = {
        merchant: action.payload
    }
    return updateObject( state, updatedState );
};

const setMenuItems = ( state, action ) => {
    const updatedState = {
        menuItems: action.payload
    }
    return updateObject( state, updatedState );
};

const setFulfilmentType = ( state, action ) => {
    const updatedState = {
        fulfilmentType: action.payload
    }
    return updateObject( state, updatedState );
};

const setFulfilmentOptions = ( state, action ) => {
    const updatedState = {
        fulfilmentOptions: action.payload
    }
    return updateObject( state, updatedState );
};

const setOrder = ( state, action ) => {
    if (Object.keys(action.payload).length === 0) {
        action.payload = initialState.order;
    }
    const updatedState = {
        order: action.payload
    }
    return updateObject( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_SLUG: return setSlug( state, action );
        case actionTypes.SET_MERCHANT: return setMerchant( state, action );
        case actionTypes.SET_MENU_ITEMS: return setMenuItems( state, action );
        case actionTypes.SET_FULFILMENT_TYPE: return setFulfilmentType( state, action );
        case actionTypes.SET_FULFILMENT_OPTIONS: return setFulfilmentOptions( state, action );
        case actionTypes.SET_ORDER: return setOrder( state, action );
        default: return state;
    }
};

export default reducer;