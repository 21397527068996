import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";

import { mpoSentry } from '../lib/Sentry';
import { logOutput, nl2br, clearLogOutput} from "../shared/utility";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';

class Logs extends Component {

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Logs','info');
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`Logs - ${process.env.REACT_APP_APP_TITLE}`}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-empty">
                        {debugMode ?
                            <React.Fragment>
                                <h3>Logs - {process.env.REACT_APP_APP_TITLE}</h3>

                                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); clearLogOutput(); window.location.reload(); }}>Clear Log</mobiscroll.Button>
                                </div>

                                <div className="mbsc-txt-s mbsc-align-left"
                                     dangerouslySetInnerHTML={{__html: nl2br(logOutput)}}></div>
                            </React.Fragment>
                            :
                            <h3>Logs not enabled</h3>
                        }
                    </div>
                </mobiscroll.Form>
            </div>
        );
    }
}

export default Logs;