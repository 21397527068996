
import {logger, isCordova, isProduction, unixUtcTimeMs} from '../shared/utility';
import Cookies from 'universal-cookie';

export const mpoSession = {

    testCookies: () => {
        const cookies = new Cookies();
        const cookieName = process.env.REACT_APP_APP_ID+'-test';
        const maxAge = parseInt(process.env.REACT_APP_SESSION_MINS,10)*60;
        const saveValue = "saveme"+unixUtcTimeMs();
        if (isProduction() && !isCordova()) {
            cookies.set(cookieName, saveValue, {
                maxAge: maxAge,
                sameSite: 'none',
                secure: true
            });
        } else {
            cookies.set(cookieName, saveValue, {
                maxAge: maxAge
            });
        }
        const cookies2 = new Cookies();
        const testValue = cookies2.get(cookieName);
        logger("testCookies: "+saveValue+"/"+testValue);
        if (testValue === saveValue) {
            cookies2.remove(cookieName);
        }
        return testValue === saveValue;
    },

    setSessionCookie: (sessionId) => {
        return new Promise((resolve, reject) => {
            if (sessionId && sessionId !== null) {
                const cookies = new Cookies();
                //logger('write mpo-sid param: '+sessionId);
                const maxAge = parseInt(process.env.REACT_APP_SESSION_MINS,10)*60;
                if (isProduction() && !isCordova()) {
                    cookies.set(process.env.REACT_APP_APP_ID + '-sid', sessionId, {
                        maxAge: maxAge,
                        sameSite: 'none',
                        secure: true
                    });
                } else {
                    cookies.set(process.env.REACT_APP_APP_ID + '-sid', sessionId, {
                        maxAge: maxAge
                    });
                }
                resolve("SSC: OK");
            } else {
                reject("SSC: sessionId empty");
            }
        });
    }

}