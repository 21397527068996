import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import ContentLoader from "react-content-loader"
//import { logger } from '../../shared/utility';
//import axiosInstance from '../../components/axios';

class CartProductItem extends Component {

    render = () => {
        //logger(this.props);

        if (this.props.item.hasOwnProperty('product_placeholder')) {
            return (
                <ContentLoader
                    key={this.props.item.key} 
                    height={100}
                    width={400}
                    speed={2}
                    primaryColor="#f3f3f3"
                    secondaryColor="#a8a8a8"
                >
                    <rect x="83" y="17" rx="5" ry="5" width="240" height="11" /> 
                    <rect x="84" y="45" rx="5" ry="5" width="207" height="9" /> 
                    <rect x="10" y="14" rx="0" ry="0" width="46" height="45" />
                </ContentLoader>
            )
        }

        return (
            <React.Fragment>
            <mobiscroll.Stepper type="number" data-role="stepper" min={0} max={this.props.item.max_qty && parseInt(this.props.item.max_qty, 10) > 0 ? this.props.item.max_qty : 999} value={this.props.item.quantity} onChange={this.props.onQuantityChangeHandler} data-mpo-cart-item-slot-id={this.props.item.cart_item_slot_id}>
                <label>{this.props.item.short_desc}</label>
                <span className="mbsc-desc">{this.props.item.is_available === 0 ? this.props.item.available_str : null}</span>
                <span className="mbsc-desc">{this.props.item.notes}</span>
                <span className="mbsc-desc">${parseFloat(this.props.item.price).toFixed(2)}</span>
            </mobiscroll.Stepper>
            </React.Fragment>
        );
    }
}

export default CartProductItem;