import {isCordova, logger} from '../shared/utility';

export const mpoGooglePay = {

    _available: false,
    _session: null,
    _store_id: null,
    _gateway: null,
    _gateway_id: null,
    _merchant_id: null,
    _merchant_name: null,
    _country_code: null,
    _currency_code: null,
    _amount: 0,
    _callback: null,

    isGooglePayAvailable: function(store_id, gateway, gateway_id, merchant_id, merchant_name, country_code, currency_code) {
        try {
            mpoGooglePay._store_id = store_id;
            mpoGooglePay._gateway = gateway;
            mpoGooglePay._gateway_id = gateway_id;
            mpoGooglePay._merchant_id = merchant_id;
            mpoGooglePay._merchant_name = merchant_name;
            mpoGooglePay._country_code = country_code;
            mpoGooglePay._currency_code = currency_code;
            if (merchant_id !== null && merchant_id !== '') {
                /*eslint-disable no-undef*/
                if (isCordova()) {
                    mpoGooglePay._available = typeof window.cordova.plugins === 'object' && typeof window.cordova.plugins.ApplePayGooglePay === 'object' && mpoGooglePay.checkForGooglePay();
                } else {
                    mpoGooglePay._available = true;// false; //typeof window.ApplePaySession !== 'undefined' && window.ApplePaySession.canMakePayments();
                }
                /*eslint-enable no-undef*/
            } else {
                mpoGooglePay._available = false;
            }
            return mpoGooglePay._available;
        } catch (error) {
            logger(error);
            return false;
        }
    },

    async checkForGooglePay() {
        /*eslint-disable no-undef*/
        return await cordova.plugins.ApplePayGooglePay.canMakePayments();
        /*eslint-enable no-undef*/
    },

    sendResult: function(success, data) {
        if (mpoGooglePay._callback !== null && typeof(mpoGooglePay._callback) === 'function') {
            mpoGooglePay._callback(success, data, 'Google', mpoGooglePay._amount);
        }
    },

    commencePayment: function(amount, desc, callback) {
        if (mpoGooglePay._available) {
            if (amount > 0) {
                mpoGooglePay._amount = parseFloat(amount);
                mpoGooglePay._callback = callback;
                /*eslint-disable no-undef*/
                if (isCordova()) {
                    let request = {
                        gateway: mpoGooglePay._gateway,
                        merchantId: mpoGooglePay._gateway_id,
                        gpMerchantName: mpoGooglePay._merchant_name,
                        gpMerchantId: mpoGooglePay._merchant_id,
                        purpose: mpoGooglePay._merchant_name, //desc,
                        amount: mpoGooglePay._amount,
                        countryCode: mpoGooglePay._country_code,
                        currencyCode: mpoGooglePay._currency_code
                    }
                    cordova.plugins.ApplePayGooglePay.makePaymentRequest(request, r => {
                            // in success callback, raw response as encoded JSON is returned. Pass it to your payment processor as is.
                            logger('[[makePaymentRequest success]]');
                            //logger(r);
                            mpoGooglePay.sendResult(true, r);
                        },
                        e => {
                            // in error callback, error message is returned.
                            // it will be "Payment cancelled" if used pressed Cancel button.
                            logger('[[makePaymentRequest error]]');
                            logger(e);
                            mpoGooglePay.sendResult(false, e);
                        }
                    )
                }
                /*eslint-enable no-undef*/
            } else {
                alert('Invalid payment amount');
            }
        } else {
            alert('Google Pay is not currently available');
        }
    },
}
