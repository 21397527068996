import React, { Component } from 'react';
//import { logger } from '../../shared/utility';

class StandingOrderItem extends Component {

    render = () => {
        //logger(this.props);

        const statusText = parseInt(this.props.item.status,10) === 1 ? 'Active' : 'Paused';
        return (
            <li key={this.props.item.id}>
                <h3 className="mpo-product-head">{this.props.item.store_name} {this.props.item.store_suburb}</h3>
                <span className="mbsc-desc mpo-product-desc">Standing Order #{this.props.item.id} ({statusText})</span>
            </li>
        );
    }

}

export default StandingOrderItem;