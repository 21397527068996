import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import ContentLoader from "react-content-loader"
import {nl2br} from "../../shared/utility";

const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
const isSingleStore = isCustomApp && process.env.REACT_APP_SINGLE_STORE === 'true';
const displaySuburbName = process.env.REACT_APP_DISPLAY_SUBURB_NAME === 'true';

class MerchantCard extends Component {

    constructor (props) {
        super(props);
        this.state = {
            heroImage: true
        }
    }

    render = () => {
        //logger(this.props);

        if (this.props.item.hasOwnProperty('merchant_placeholder')) {
            return (
                <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-4 mbsc-col-xl-4">
                    <mobiscroll.Card>
                        <mobiscroll.CardContent>
                            <ContentLoader 
                                height={160}
                                width={400}
                                speed={2}
                                primaryColor="#f3f3f3"
                                secondaryColor="#a8a8a8">
                                <rect x="70" y="15" rx="4" ry="4" width="117" height="6" /> 
                                <rect x="70" y="35" rx="3" ry="3" width="85" height="6" /> 
                                <rect x="0" y="80" rx="3" ry="3" width="350" height="6" /> 
                                <rect x="0" y="100" rx="3" ry="3" width="380" height="6" /> 
                                <rect x="0" y="120" rx="3" ry="3" width="201" height="6" /> 
                                <circle cx="30" cy="30" r="30" />
                            </ContentLoader>
                        </mobiscroll.CardContent>
                    </mobiscroll.Card>
                </div>
            )
        }

        const defaultLogoSquare = process.env.REACT_APP_DEFAULT_LOGO_SQUARE;
        const displayLogoSquare = process.env.REACT_APP_DISPLAY_LOGO_SQUARE === 'true';
        const heroImage = this.state.heroImage && this.props.item.hasOwnProperty('hero_img_url') && this.props.item.hero_img_url !== '' ? this.props.item.hero_img_url : '';
        const displayHeroImage = heroImage !== '';

        const isActive = this.props.item.status === 'online' || this.props.item.status === 'offline';
        const comingSoonText = this.props.item.hasOwnProperty('cstext') ? this.props.item.cstext : 'Coming soon';
        const isOnline = this.props.item.status === 'online' || parseInt(this.props.item.always_open, 10) === 1 || parseInt(this.props.item.order_for_later, 10) === 1;
        const orderForLater = this.props.item.status === 'offline' && parseInt(this.props.item.always_open, 10) === 0 && parseInt(this.props.item.order_for_later, 10) === 1;
        const orderText = orderForLater ? 'Order for Later' : 'Order Now';
        const orderBtnStyle = !isOnline || orderForLater ? {color: '#fff', fontSize: '0.82em'} : {color: '#fff'};
        const logoOpacity = isActive ? 1 : 0.5;

        let info = [];
        if (this.props.item.distance_from_position !== null && this.props.item.distance_from_position >= 0) {
            info.push(<span key={this.props.item.merchant_id.toString()+"dfp"}>
                <span className="empty icon fas fa-map-marker-alt fa-xs"></span>
                <span className="mbsc-txt-s" style={{paddingLeft: "0.1em", paddingRight: "0.75em"}}>{parseFloat(this.props.item.distance_from_position).toFixed(1)+'km'}</span>
            </span>)
        }
        if (this.props.item.hasOwnProperty('fulfilment') && this.props.item.fulfilment.length > 0) {
            for (let ft in this.props.item.fulfilment) {
                //logger(this.props.item.fulfilment[ft]);
                if (this.props.item.fulfilment[ft].hasOwnProperty('icon') && this.props.item.fulfilment[ft].icon !== '') {
                    var fulfilmentIconClass = "empty icon fas fa-xs fa-"+this.props.item.fulfilment[ft].icon;
                    if (this.props.item.fulfilment[ft].code !== 'catering') {
                        info.push(<span key={this.props.item.merchant_id.toString()+"ft"+ft} className={fulfilmentIconClass} style={{paddingLeft: "0.5em"}} title={this.props.item.fulfilment[ft].verb}></span>)
                    }
                }
            }
        } 
        if (this.props.item.hasOwnProperty('payment_methods') && this.props.item.payment_methods.length > 0) {
            for (let pm in this.props.item.payment_methods) {
                var paymentIconClass = "empty icon fas fa-xs fa-"+this.props.item.payment_methods[pm];
                var paymentIconStyle = parseInt(pm,10) === 0 ? {paddingLeft: "1.25em"} : {paddingLeft: "0.5em"};
                info.push(<span key={this.props.item.merchant_id.toString()+"pm"+pm} className={paymentIconClass} style={paymentIconStyle}></span>)
            }
        } 
        if (info.length > 0) {
            let infoStyle = {display:'inline-block',margin:'0.5em 0.25em',padding:'0.625em 1em'};
            if (displayHeroImage) {
                infoStyle.color = '#fff';
            }
            info = <span style={infoStyle}>{info}</span>
        }

        return (
            <div className="mbsc-col-12 mbsc-col-xs-12 mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-lg-4 mbsc-col-xl-4">
                {displayHeroImage ? 
                    <mobiscroll.Card style={{margin: "0.5em"}}>
                    <div className="md-card-overlay">
                        <mobiscroll.CardContent onClick={(e) => { e.preventDefault(); if (isActive) this.props.history.push('/menu/'+this.props.item.menus[0].name) }}>
                            <img src={heroImage} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; this.setState({heroImage: false}) }} />
                            {displayLogoSquare ? <img className="mbsc-pull-left" style={{maxWidth: "80px", paddingRight: "1em"}} src={this.props.item.logo_square_url} alt="Logo" onError={(e)=>{e.target.onerror = null; e.target.src=defaultLogoSquare}} /> : null}
                            {/*
                            <span className="mbsc-ms-badge mpo-merchant-badge">{isActive ? "Online" : "Offline"}</span>
                            */}
                            <mobiscroll.CardTitle className="md-card-hero-title">{this.props.item.name} {isCustomApp && !isSingleStore && displaySuburbName ? this.props.item.suburb : null}</mobiscroll.CardTitle>
                            <mobiscroll.CardSubtitle className="md-card-hero-subtitle"><span dangerouslySetInnerHTML={{__html: nl2br(this.props.item.address1)}}></span> {!isCustomApp || isSingleStore || !displaySuburbName ? this.props.item.suburb : null}</mobiscroll.CardSubtitle>
                        </mobiscroll.CardContent>
                        <mbsc-footer>
                            {/* todo: add/remove favourite, including toast to confirm added/removed */}
                            {/* <button className="mbsc-btn-flat mbsc-pull-right" data-icon="heart2" title="Favourite">&nbsp;</button> */}
                            {info}
                            {isActive ?
                                <React.Fragment>
                                    {isOnline ?
                                        <mobiscroll.Button className="mbsc-pull-right" color="success" style={orderBtnStyle} onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/'+this.props.item.menus[0].name) }}>{orderText}</mobiscroll.Button>
                                        :
                                        <mobiscroll.Button className="mbsc-pull-right" color="danger" style={orderBtnStyle} onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/'+this.props.item.menus[0].name) }}>Closed</mobiscroll.Button>
                                    }
                                </React.Fragment>
                                :
                                <span className="mbsc-pull-right" style={{display:'inline-block',margin:'0.5em 0.25em',padding:'0.625em 1em',color:'#fff'}}>{comingSoonText}</span>
                            }
                        </mbsc-footer>
                    </div>
                    </mobiscroll.Card>
                :
                <mobiscroll.Card style={{margin: "0.5em"}}>
                    <mobiscroll.CardContent onClick={(e) => { e.preventDefault(); if (isActive) this.props.history.push('/menu/'+this.props.item.menus[0].name) }}>
                    {displayLogoSquare ? 
                        <div>
                            {/* <div className="md-logo-overlay">Closed</div> */}
                            <img className="mbsc-pull-left" style={{maxWidth: "80px", paddingRight: "1em", opacity: logoOpacity}} src={this.props.item.logo_square_url} alt="Logo" onError={(e)=>{e.target.onerror = null; e.target.src=defaultLogoSquare}} /> 
                        </div>
                    : null}
                    <mobiscroll.CardTitle>{this.props.item.name} {isCustomApp && !isSingleStore && displaySuburbName ? this.props.item.suburb : null}</mobiscroll.CardTitle>
                    <mobiscroll.CardSubtitle><span dangerouslySetInnerHTML={{__html: nl2br(this.props.item.address1)}}></span> {!isCustomApp || isSingleStore || !displaySuburbName ? this.props.item.suburb : null}</mobiscroll.CardSubtitle>
                    </mobiscroll.CardContent>
                    <mbsc-footer>
                        {/* todo: add/remove favourite, including toast to confirm added/removed */}
                        {/* <button className="mbsc-btn-flat mbsc-pull-right" data-icon="heart2" title="Favourite">&nbsp;</button> */}
                        {info}
                        {isActive ?
                            <React.Fragment>
                                {isOnline ?
                                    <mobiscroll.Button className="mbsc-pull-right" color="success" style={orderBtnStyle} onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/'+this.props.item.menus[0].name) }}>{orderText}</mobiscroll.Button>
                                    :
                                    <mobiscroll.Button className="mbsc-pull-right" color="danger" style={orderBtnStyle} onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/'+this.props.item.menus[0].name) }}>Closed</mobiscroll.Button>
                                }
                            </React.Fragment>
                            :
                            <span className="mbsc-pull-right" style={{display:'inline-block',margin:'0.5em 0.25em',padding:'0.625em 1em'}}>{comingSoonText}</span>
                        }
                    </mbsc-footer>
                </mobiscroll.Card>
            }
            </div>
        );
    }
}

export default withRouter(MerchantCard);