import React, { Component } from 'react';
import { nl2br } from '../../shared/utility';

class PromoItem extends Component {

    render = () => {
        //logger(this.props);
        return (
            <div key={this.props.item.id} style={{padding: "0.5em 0", borderBottom: "#efeff4 solid 1px"}}>
                <h5 className="mpo-promo-head">{this.props.item.title}</h5>
                <span className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(this.props.item.desc)}}></span>
                {this.props.item.promo_code ? <span className="mbsc-txt-s"><br />Use promo code <span style={{fontWeight: "bold"}}>{this.props.item.promo_code}</span></span> : null }
                {this.props.item.type === "loyalty" && !this.props.authed ? <span className="mbsc-desc">Sign in to earn and redeem rewards.</span> : null}
                {this.props.item.terms ? <span className="mbsc-desc">{this.props.item.terms}</span> : null}
            </div>
        );
    }
}

export default PromoItem;