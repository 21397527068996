import React, { Component } from 'react';
//import { logger } from '../../shared/utility';
import mobiscroll from "@mobiscroll/react";

class RewardItem extends Component {

    render = () => {
        //logger(this.props);

        return (
            <li key={this.props.item.id}>
                {/*<mobiscroll.Button className="mbsc-pull-right" onClick={(e) => e.preventDefault()}>Order Now</mobiscroll.Button>*/}
                <h3>{this.props.item.store_name} {this.props.item.store_suburb}</h3>
                <span className="mbsc-desc">{this.props.item.rewards_name}</span>
                <mobiscroll.Rating className="reward-item" val="right" max={parseInt(this.props.item.points_required,10)} value={parseInt(this.props.item.points_balance,10)} step={0} template="{value}/{max}">
                </mobiscroll.Rating>
            </li>
        );
    }

}

export default RewardItem;