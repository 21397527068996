import mobiscroll from "@mobiscroll/react";
import { logger, isCordova } from '../shared/utility';

export const mpoScanner = {

    Scan: (callbackSuccess, callbackError) => {
        if (isCordova()) {
            /*eslint-disable no-undef*/
            cordova.plugins.barcodeScanner.scan(
                callbackSuccess,
                callbackError,
                {
                    preferFrontCamera: false, // iOS and Android
                    showFlipCameraButton: false, // iOS and Android
                    showTorchButton: true, // iOS and Android
                    torchOn: false, // Android, launch with the torch switched on (if available)
                    saveHistory: false, // Android, save scan history (default false)
                    prompt: "Place the QR code inside the scan area", // Android
                    resultDisplayDuration: 1000, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                    formats: "QR_CODE", // default: all but PDF_417 and RSS_EXPANDED
                    orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                    disableAnimations: true, // iOS
                    disableSuccessBeep: false // iOS
                }
            );
            /*eslint-enable no-undef*/
        } else {
            mobiscroll.toast({message: "Download the app from your app store to scan codes", color: 'info'});
        }
    },

    ScanQrCode: (callback) => {
        mpoScanner.Scan(callback, mpoScanner._Scan_error);
    },

    _Scan_error: (error) => {
        logger('mpoScanner._Scan_error: ' + error);
        mobiscroll.toast({message: "Scan failed: " + error, color: 'danger'});
    },

}