import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import { mpoSentry } from '../../lib/Sentry';
import AccountBottomNav from '../../components/account/BottomNav';
import {Helmet} from "react-helmet";

export default class AccountInvoices extends Component {

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Invoices','info');
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_TITLE} Invoices`}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-empty">
                        <h3>Currently unavailable</h3>
                    </div>
                </mobiscroll.Form>

                <AccountBottomNav />
            </div>
        );
    }
}