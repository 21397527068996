import {isCordova, isProduction, logger} from '../shared/utility';

export const mpoApplePay = {

    _available: false,
    _session: null,
    _store_id: null,
    _merchant_id: null,
    _merchant_name: null,
    _country_code: null,
    _currency_code: null,
    _amount: 0,
    _callback: null,

    isApplePayAvailable: function(store_id, merchant_id, merchant_name, country_code, currency_code) {
        try {
            mpoApplePay._store_id = store_id;
            mpoApplePay._merchant_id = merchant_id;
            mpoApplePay._merchant_name = merchant_name;
            mpoApplePay._country_code = country_code;
            mpoApplePay._currency_code = currency_code;
            if (merchant_id !== null && merchant_id !== '') {
                /*eslint-disable no-undef*/
                if (isCordova()) {
                    mpoApplePay._available = typeof window.cordova.plugins === 'object' && typeof window.cordova.plugins.ApplePayGooglePay === 'object' && mpoApplePay.checkForApplePay();
                } else {
                    mpoApplePay._available = typeof window.ApplePaySession !== 'undefined' && window.ApplePaySession.canMakePayments();
                }
                /*eslint-enable no-undef*/
            } else {
                mpoApplePay._available = false;
            }
            return mpoApplePay._available;
        } catch (error) {
            logger(error);
            return false;
        }
    },

    async checkForApplePay() {
        /*eslint-disable no-undef*/
        return await cordova.plugins.ApplePayGooglePay.canMakePayments();
        /*eslint-enable no-undef*/
    },

    sendResult: function(success, data) {
        if (mpoApplePay._callback !== null && typeof(mpoApplePay._callback) === 'function') {
            mpoApplePay._callback(success, data, 'Apple', mpoApplePay._amount);
        }
    },

    commencePayment: function(amount, desc, callback) {
        if (mpoApplePay._available) {
            if (amount > 0) {
                mpoApplePay._amount = parseFloat(amount);
                mpoApplePay._callback = callback;
                /*eslint-disable no-undef*/
                if (isCordova()) {
                    let request = {
                        merchantId: mpoApplePay._merchant_id, // obtain it from https://developer.apple.com/account/resources/identifiers/list/merchant
                        purpose: mpoApplePay._merchant_name, //desc,
                        amount: mpoApplePay._amount,
                        countryCode: mpoApplePay._country_code,
                        currencyCode: mpoApplePay._currency_code
                    }
                    cordova.plugins.ApplePayGooglePay.makePaymentRequest(request, r => {
                            // in success callback, raw response as encoded JSON is returned. Pass it to your payment processor as is.
                            logger('[[makePaymentRequest success]]');
                            //logger(r);
                            mpoApplePay.sendResult(true, r);
                        },
                        e => {
                            // in error callback, error message is returned.
                            // it will be "Payment cancelled" if used pressed Cancel button.
                            logger('[[makePaymentRequest error]]');
                            logger(e);
                            mpoApplePay.sendResult(false, e);
                        }
                    )
                } else {
                    let request = {
                        countryCode: mpoApplePay._country_code,
                        currencyCode: mpoApplePay._currency_code,
                        //supportedNetworks: ['visa', 'masterCard', 'amex'],
                        supportedNetworks: ['visa', 'masterCard'],
                        merchantCapabilities: ['supports3DS'],
                        total: {label: mpoApplePay._merchant_name, amount: mpoApplePay._amount},
                    }
                    mpoApplePay._session = new window.ApplePaySession(3, request);
                    mpoApplePay._session.onvalidatemerchant = mpoApplePay.onValidateMerchant;
                    mpoApplePay._session.onpaymentmethodselected = mpoApplePay.onPaymentMethodSelected;
                    mpoApplePay._session.onpaymentauthorized = mpoApplePay.onPaymentAuthorized;
                    mpoApplePay._session.oncancel = mpoApplePay.onCancel;
                    //logger(mpoApplePay._session);
                    mpoApplePay._session.begin();
                }
                /*eslint-enable no-undef*/
            } else {
                alert('Invalid payment amount');
            }
        } else {
            alert('Apple Pay is not currently available');
        }
    },

    onValidateMerchant: function(event) {
        logger('[[session.onvalidatemerchant]]');
        //logger(event);
        //logger('[['+event.validationURL+']]');
        var promise = mpoApplePay.performValidation(event.validationURL);
        promise.then(function (merchantSession) {
            logger('[[session.onvalidatemerchant merchantSession]]');
            //logger(merchantSession);
            if (mpoApplePay._session) {
                mpoApplePay._session.completeMerchantValidation(merchantSession);
            } else {
                logger("[[session.onvalidatemerchant mpoApplePay._session null 2]]");
            }
        }, function(error) {
            mpoApplePay._session.abort();
            mpoApplePay.sendResult(false, error);
        });
    },

    performValidation: function(url) {
        return new Promise(function(resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function() {
                logger('[[onreadystatechange]] '+this.readyState+'|'+this.status);
                if (this.readyState === 4) {
                    var data = JSON.parse(this.responseText);
                    if (this.status === 200) {
                        logger('[[performValidation data]]')
                        //logger(this.responseText);
                        resolve(data);
                    } else {
                        logger('[[performValidation error]]');
                        logger(this.responseText);
                        reject(data.error);
                    }
                }
            };
            xhr.onerror = reject;
            let api_url = 'https://'+(isProduction() ? 'api' : 'stg')+'.mypreorder.com.au/services/c/apple_pay.php';
            xhr.open('GET', api_url+'?key=' + process.env.REACT_APP_API_TOKEN + '&url=' + url + '&domain=' + window.location.hostname + '&store_id=' + mpoApplePay._store_id + '&merchant_id=' + mpoApplePay._merchant_id);
            xhr.send();
        });
    },

    onPaymentMethodSelected: function(event) {
        logger('[[session.onpaymentmethodselected]]');
        //logger(event);
        mpoApplePay._session.completePaymentMethodSelection({ type: 'final', label: mpoApplePay._merchant_name, amount: mpoApplePay._amount }, []);
    },

    onPaymentAuthorized: function (event) {
        logger('[[session.onpaymentauthorized]]');
        //NB: This is the first stage when you get the *full shipping address* of the customer, in the event.payment.shippingContact object
        //logger(event.payment);
        mpoApplePay.sendResult(true, event.payment.token);
    },

    successPayment: function () {
        if (!isCordova() && mpoApplePay._session) {
            try {
                /*eslint-disable no-undef*/
                mpoApplePay._session.completePayment(ApplePaySession.STATUS_SUCCESS);
                /*eslint-enable no-undef*/
            } catch (error) {
                logger(error);
            }
        }
    },

    failedPayment: function (error) {
        if (!isCordova() && mpoApplePay._session) {
            try {
                /*eslint-disable no-undef*/
                mpoApplePay._session.completePayment(ApplePaySession.STATUS_FAILURE, [error]);
                /*eslint-enable no-undef*/
            } catch (error) {
                logger(error);
            }
        }
    },

    onCancel: function(event) {
        logger('[[session.cancel]]');
        //logger(event);
        mpoApplePay._session = null;
        mpoApplePay.sendResult(false, "Payment cancelled");
    }

}
