import React, { Component } from 'react';
import { connect } from 'react-redux';
import mobiscroll from "@mobiscroll/react";
import * as Sentry from "@sentry/react";

import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import axiosInstance from '../../components/axios';
import { googleLogout } from '@react-oauth/google';
import {logger, isCordova, isGoogleSigninLoaded} from "../../shared/utility";
//import { clear } from "redux-localstorage-simple"

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';

class AccountLogout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoggingOut: false
        }
    }

    sendToServer = () => {

        const data = {
            RequestAction: 'Logout'
        };
        mpoSentry.addBreadcrumb('action',data.RequestAction,'info');

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "SUCCESS") {

                // logout of connected auth services
                try {
                    if (this.props.user.customer.is_google_connected) {
                        if (isCordova() && isGoogleSigninLoaded()) {
                            if (debugMode) {

                                // clear the OAuth2 token
                                window.plugins.googleplus.logout(
                                    msg => {
                                        if (debugMode) logger(msg);
                                    }
                                );
                                /*
                                // clear the OAuth2 token, forget which account was used to login, and disconnect that account from the app
                                window.plugins.googleplus.disconnect(
                                    msg => {
                                        if (debugMode) logger(msg);
                                    }
                                );
                                 */
                            } else {
                                // clear the OAuth2 token
                                window.plugins.googleplus.logout(
                                    msg => {
                                        if (debugMode) logger(msg);
                                    }
                                );
                            }
                        } else if (!isCordova()) {
                            googleLogout();
                        }
                    }
                } catch(error) {
                    mpoSentry.captureException(error);
                }

                // set customer and redirect
                mobiscroll.toast({message: "Logout success", color: 'success'});
                this.props.updateStateWithCart({
                    order: {
                        items: [
                    ]}
                });
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);

                //clear();
                //this.props.history.push('/');

            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], 'warning');
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error LO1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    componentDidMount = () => {

        mpoSentry.addBreadcrumb('nav','Logout','info');

        if (!this.state.isLoggingOut) {
            this.sendToServer();
            this.setState({isLoggingOut: true});
            Sentry.configureScope((scope) => {
                scope.setUser({
                    "id": 0,
                    "email": ""
                });
            });
        }

    }

    render = () => {

        return (
            <div className="app-tab">
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-empty">
                        <h3>Logging out ...</h3>
                    </div>
                </mobiscroll.Form>
            </div>
        );

    }

}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateWithCart: (cart) => {
            dispatch(actions.setCartAction(cart));
            dispatch(actions.setOrderAction(cart.order));
        },
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountLogout);
