import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
//import { logger } from '../../shared/utility';

import { mpoSentry } from '../../lib/Sentry';
import {attachDeviceInfoToData, getPopupResponsiveSettings, isCordova} from "../../shared/utility";
import {mpoFirebase} from "../../lib/Firebase";
import {mpoOneSignal} from "../../lib/OneSignal";

let addingToCart = false;

class Modifiers extends Component {

    constructor(props) {
        super(props);
        //console.log('Modifiers', props);
        this.state = {
            isLoading: false,
            store_id: 0,
            menu_id: 0,
            menu_item_id: 0,
            name: "",
            desc: "",
            qty: 1,
            min_qty: 1,
            max_qty: 99,
            qty_label: "Quantity",
            availability_notice: "",
            base_price: 0.00,
            thumb_url: "",
            image_url: "",
            cart_item_slot_id: -1,
            modifiers: [],
            selectedOptions: null,
            addToCartLabel: "Add To Cart",
            addToCartIcon: "cart",
            goToCart: false,
            displayQty: true,
            validateOnly: this.props.hasOwnProperty('validateOnly') && this.props.validateOnly === true
        }
        
    }

    onShow = (event, inst) => {
        //logger('Modifiers onShow');
        addingToCart = false;
        mobiscroll.notification.dismiss();

        mpoFirebase.LogEvent('view_item', {
            currency: this.props.locale.hasOwnProperty('currency_code') ? this.props.locale.currency_code : '',
            //items: this.state.name,
            value: this.state.base_price,
        });

    }

    onClose = () => {
        this.refs.popupModifiers.instance.hide();
    }

    addToCartHandler = (event, inst) => {
        if (!this.state.isLoading && !addingToCart) {
            //logger(this.state.isLoading);
            //logger(addingToCart);
            try {
                addingToCart = true;
                //console.log(event);
                //console.log(inst);
                //console.log(this.state);
                //console.log(this);

                // post to backend here (try to call parent)
                /*
                //console.log(this.refs);
                if (this.refs.hasOwnProperty("mod_226_337")) {
                    //console.log(this.refs.mod_226_337.instance);
                    //console.log(this.refs.mod_226_337.instance.getVal());
                }
                */

                let modifiers = this.state.modifiers;
                let selected = {};
                let errors = [];

                modifiers.map((modifier, idx)=> {
                    //console.log(modifier);
                    if (this.refs.hasOwnProperty("mod_"+modifier.id)) {
                        let vals = this.refs["mod_"+modifier.id].instance.getVal();
                        //console.log('vals', vals);
                        
                        if (modifier.multiselect) {
                            // vals is an array
                            if (vals.length === 0) {
                                if (modifier.mandatory) {
                                    errors.push(modifier.name + ' is required');
                                }
                            } else if (modifier.hasOwnProperty('max_select') && parseInt(modifier.max_select,10) > 0 && vals.length > parseInt(modifier.max_select,10)) {
                                errors.push('Choose up to ' + modifier.max_select + ' options for ' + modifier.name);
                            } else {
                                vals.map((v) => {
                                    selected["option_"+v] = parseInt(v, 10);
                                });
                            }
                        } else {
                            // vals is a single value
                            if (vals === undefined || vals === null || isNaN(vals) || parseInt(vals, 10) === 0) {
                                if (modifier.mandatory) {
                                    errors.push(modifier.name + ' is required');
                                }    
                            } else {
                                selected["group_"+modifier.id] = parseInt(vals, 10);
                            }
                        }
                    }
                });

                if (errors.length === 0) {

                    let orderItem = {
                        menu_item_id: this.state.menu_item_id,
                        quantity: this.state.qty,
                        options: selected,
                        cart_item_slot_id: this.state.cart_item_slot_id
                    }

                    if (this.state.validateOnly) {

                        this.props.updateStateWithOrder(orderItem);
                        this.refs.popupModifiers.instance.hide();

                    } else {

                        const data = {
                            RequestAction: 'ManageCart',
                            sub_action: 'add_to_order',
                            store_id: this.state.store_id,
                            menu_id: this.state.menu_id,
                            menu_item_data: orderItem
                        };

                        attachDeviceInfoToData(data);
                        if (isCordova() && mpoOneSignal.IsRegistered()) {
                            data.pn_data = mpoOneSignal.GetPnData();
                        }

                        // send data to backend
                        //console.log(data);

                        this.addToCartPostDataHandler(data);

                    }

                } else {
                    addingToCart = false;
                    mobiscroll.toast({message: errors.toString(), color: 'danger'});
                    mpoSentry.captureMessage(errors.toString(), 'warning');
                }
            } finally {
                
            }
        }
    }

    addToCartPostDataHandler = (data) => {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    this.props.updateStateWithOrder(response.data.Response);
                    if (this.props.history) {
                        mobiscroll.snackbar({
                            message: 'Added to Cart',
                            color: 'success',
                            duration: 10000,
                            button: {
                                text: 'Proceed to Cart',
                                icon: 'cart',
                                action: (p) => {
                                    this.props.history.push('/cart');
                                }
                            }
                        });
                    }
                    this.setState({ isLoading: false });
                    //logger(this.refs);
                    //addingToCart = false;
                    mpoFirebase.LogEvent('add_to_cart', {
                        currency: this.props.locale.hasOwnProperty('currency_code') ? this.props.locale.currency_code : '',
                        //items: this.state.name,
                        value: this.state.base_price,
                    });
                    this.refs.popupModifiers.instance.hide();
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                    this.setState({ isLoading: false });
                    addingToCart = false;
                }
            })
            .catch(error => {
                //console.log(error);
                addingToCart = false;
                mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
                mpoSentry.captureException(error);
                this.setState({ isLoading: false });
            });            
        }
    }

    qtyChange = (e) => {
        this.setState({ qty: e.target.valueAsNumber });
    }

    setData = (event, inst) => {
        //console.log(event);
        //console.log(inst);
        //console.log(inst.getVal());
        //this.setState({ selectedData: inst.getVal() });
    }

    render = () => {
        //console.log('mods render');
        let modifiers = this.state.modifiers;
        let addToCartLabel = this.state.addToCartLabel;
        let addToCartIcon = this.state.addToCartIcon;
        return (
            <mobiscroll.Popup
                ref="popupModifiers"
                responsive={getPopupResponsiveSettings(mobiscroll.platform.name === 'ios')}
                //display="center"
                layout="liquid"
                buttons={[{
                    text: addToCartLabel,
                    icon: addToCartIcon,
                    handler: this.addToCartHandler,
                    //cssClass: 'mbsc-windows mbsc-btn-success mbsc-btn',
                    //disabled: this.state.isLoading || addingToCart
                }]}
                //headerText={this.state.name}
                onShow={this.onShow}
                /*
                onBeforeShow={(event, inst) => {
                    // stepper min, max and value not setting from state (state is correct)
                    //console.log('stuff');
                }}
                */
            >
                <div onClick={this.onClose} className="mbsc-ic mbsc-ic-close" style={{position:'absolute',top:'0.5em',right:'1em',cursor:'pointer',zIndex:5,color:'grey'}}></div>
                <mobiscroll.Form labelStyle="stacked">
                        {/*{this.state.desc ? <span>{this.state.desc}</span> : ''}*/}
                        {/*{this.state.availability_notice ? <span>{this.state.availability_notice}</span> : ''}*/}
                        {/*this.state.image_url ? <img src={this.state.image_url} className="mbsc-media-fluid" alt="Product image" style={{maxHeight: "500px"}} onError={(e)=>{e.target.onerror = null; this.setState({image_url: ""}) }} /> : null*/}
                        {this.state.image_url ?
                            <mobiscroll.Card style={{ margin: "0.5em 0", borderRadius: '0' }}>
                                <div className="md-card-overlay">
                                    <mobiscroll.CardContent>
                                        <img src={this.state.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                                        <mobiscroll.CardTitle className="md-card-hero-title">{this.state.name}</mobiscroll.CardTitle>
                                    </mobiscroll.CardContent>
                                </div>
                            </mobiscroll.Card>
                            :
                            <h4 style={{ margin: "0.5em 0" }}>{this.state.name}</h4>
                        }
                        {this.state.displayQty ?
                        <mobiscroll.Stepper type="number" data-role="stepper" min={this.state.min_qty} max={this.state.max_qty} value={this.state.qty} onChange={this.qtyChange}>
                            <label>{this.state.qty_label}</label>
                        </mobiscroll.Stepper>
                            : null}

                        {modifiers.length > 0 ? <mobiscroll.FormGroupTitle>Options</mobiscroll.FormGroupTitle> : ""}
                        {
                            modifiers.map((modifier, idx)=> {
                                //console.log(modifier);
                                let options = modifier.options;
                                let defaults = [];
                                if (this.state.selectedOptions !== null && Object.keys(this.state.selectedOptions).length > 0) {
                                    for (var key in this.state.selectedOptions) {
                                        for (var i = 0; i < options.length; i++) {
                                            if (parseInt(options[i].id,10) === parseInt(this.state.selectedOptions[key],10)) {
                                                defaults.push(this.state.selectedOptions[key]);
                                                break;
                                            }
                                        }
                                    }
                                } else {
                                    options.map((option, idx) => {
                                        if (option.is_default) {
                                            defaults.push(option.id);
                                        }
                                    });
                                }

                                return (
                                        <label key={"mod_"+modifier.id}>
                                            {/*modifier.mandatory ? add some validation etc, try invalid=[''] */}
                                            {modifier.name}
                                            <mobiscroll.Select
                                                ref={"mod_"+modifier.id}
                                                select={modifier.multiselect ? "multiple" : "single"}
                                                multiline={2}
                                                rows={5}
                                                value={defaults}
                                                placeholder={modifier.multiselect ? (modifier.hasOwnProperty('max_select') && parseInt(modifier.max_select,10) > 0 ? "select up to "+modifier.max_select : "select one or more") : "select one"}
                                                onSet={this.setData}
                                            >
                                                {options.map((option, idx) => <option key={"opt_"+option.id} value={option.id}>{option.desc}{parseFloat(option.price_offset) > 0 ? " (add "+parseFloat(option.price_offset).toFixed(2)+")" : (parseFloat(option.price_offset) < 0 ? " (sub "+parseFloat(Math.abs(option.price_offset)).toFixed(2)+")" : "")}</option>)}
                                            </mobiscroll.Select>
                                        </label>
                                    );

                                /*
                                return (
                                    <mobiscroll.Dropdown label={modifier.name} id={modifier.id}>
                                        {modifier.mandatory ? "" : <option>Select</option> }
                                        {options.map((option, idx) => <option value={option.id}>{option.desc}{option.price_offset > 0 ? " (add "+option.price_offset+")" : (option.price_offset < 0 ? " (sub "+option.price_offset+")" : "")}</option>)}
                                    </mobiscroll.Dropdown>
                                );
                                */
                            })
                        }
                </mobiscroll.Form>
            </mobiscroll.Popup>
        );
    }

};

export default Modifiers;