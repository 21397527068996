import { logger, unixUtcTimeMs } from "../shared/utility";

export const mpoFulfilment = {

    fulfilmentTypeUnknown: "",
    fulfilmentTypeNone: "None",
    fulfilmentTypePickup: "Pickup",
    fulfilmentTypeDelivery: "Deliver",
    fulfilmentTypeDinein: "Dine-in", // table num
    fulfilmentTypeBooking: "Booking", // ref & pax
    fulfilmentTypeCustom: "Custom",

    fulfilmentTypeCodeUnknown: "",
    fulfilmentTypeCodeNone: "none",
    fulfilmentTypeCodePickup: "pickup",
    fulfilmentTypeCodeDelivery: "deliver",
    fulfilmentTypeCodeDinein: "table",
    fulfilmentTypeCodeBooking: "booking",
    fulfilmentTypeCodeCustom: "custom",

    fulfilmentTimeFrameUnknown: "",
    fulfilmentTimeFrameNone: "none",
    fulfilmentTimeFrameDateTime: "datetime",
    fulfilmentTimeFrameLocation: "location",
    
    fulfilmentTimeTypeUnknown: "",
    fulfilmentTimeTypeNone: "none",
    fulfilmentTimeTypeUser: "user",
    fulfilmentTimeTypeDeliverySlots: "slots",
    fulfilmentTimeTypeDeliveryStops: "stops",
    fulfilmentTimeTypePickupLocations: "locations",

    selectFulfilmentType: (fulfilmentTypes, fulfilmentTypeCode) => {
        let selectedFulfilmentType = null;
        for (let ft in fulfilmentTypes) {
            if (fulfilmentTypes[ft].code === fulfilmentTypeCode) {
                selectedFulfilmentType = fulfilmentTypes[ft];
                break;
            }
        }
        return selectedFulfilmentType;
    },

    getDefaultFulfilmentOptions: (fulfilmentTypeCode = null, asap = true) => {
        return {
            code: fulfilmentTypeCode,
            asap: asap,
            datetime: null,
            datetimeVal: null,
            deliverySlotId: 0,
            deliverySlotDesc: "",
            deliveryStopId: 0,
            deliveryStopDesc: "",
            pickupLocationId: 0,
            pickupLocationDesc: "",
            dineinTableNum: "",
            dineinBookingName: "",
            dineinBookingRef: "",
            dineinBookingPax: 2,
        }    
    },

    validFulfilment:  (fulfilmentType, fulfilmentOptions) => {
        //logger(fulfilmentType);
        //logger(fulfilmentOptions);
        const isValid = fulfilmentType !== undefined && Object.keys(fulfilmentType).length > 0 && 
                fulfilmentOptions !== undefined && Object.keys(fulfilmentOptions).length > 0 &&
                fulfilmentType.hasOwnProperty('fulfilment_types') && fulfilmentType.fulfilment_types !== undefined &&
                fulfilmentOptions.hasOwnProperty('code') && fulfilmentType.fulfilment_types.hasOwnProperty(fulfilmentOptions.code);
        //logger('validFulfilment: '+isValid);
        return isValid;
    },

    getFulfilmentType: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.validFulfilment(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.code;
        }
        return mpoFulfilment.fulfilmentTypeUnknown;
    },

    isPickupFulfilmentType: (fulfilmentType, fulfilmentOptions) => {
        return mpoFulfilment.getFulfilmentType(fulfilmentType, fulfilmentOptions) === mpoFulfilment.fulfilmentTypePickup;
    },

    isFulfilmentAsapAnOption: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.validFulfilment(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('asap') && parseInt(selectedFulfilmentType.asap,10) === 1;
        }
        return false;
    },

    isFulfilmentDateTimeRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.validFulfilment(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('time_type') && selectedFulfilmentType.time_type !== mpoFulfilment.fulfilmentTimeTypeNone;
        }
        return false;
    },

    isDeliveryFulfilmentType: (fulfilmentType, fulfilmentOptions) => {
        return mpoFulfilment.getFulfilmentType(fulfilmentType, fulfilmentOptions) === mpoFulfilment.fulfilmentTypeDelivery;
    },

    isDeliveryAddressRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDeliveryFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('address_required') && parseInt(selectedFulfilmentType.address_required,10) === 1;
        }
        return false;
    },

    isDeliverySlotRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDeliveryFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('time_type') && selectedFulfilmentType.time_type === mpoFulfilment.fulfilmentTimeTypeDeliverySlots;
        }
        return false;
    },

    isValidDeliverySlot: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDeliverySlotRequired(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('deliverySlotId') && parseInt(fulfilmentOptions.deliverySlotId,10) > 0;
        }
        return false;
    },

    getSelectedDeliverySlotDesc: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isValidDeliverySlot(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('deliverySlotDesc') && fulfilmentOptions.deliverySlotDesc !== null ? fulfilmentOptions.deliverySlotDesc : "";
        }
        return "";
    },

    isDeliveryStopRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDeliveryFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('time_type') && selectedFulfilmentType.time_type === mpoFulfilment.fulfilmentTimeTypeDeliveryStops;
        }
        return false;
    },

    isValidDeliveryStop: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDeliveryStopRequired(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('deliveryStopId') && parseInt(fulfilmentOptions.deliveryStopId,10) > 0;
        }
        return false;
    },

    getSelectedDeliveryStopDesc: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isValidDeliveryStop(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('deliveryStopDesc') && fulfilmentOptions.deliveryStopDesc !== null ? fulfilmentOptions.deliveryStopDesc : "";
        }
        return "";
    },

    isPickupLocationRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isPickupFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('time_type') && selectedFulfilmentType.time_type === mpoFulfilment.fulfilmentTimeTypePickupLocations;
        }
        return false;
    },

    isValidPickupLocation: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isPickupLocationRequired(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('pickupLocationId') && parseInt(fulfilmentOptions.pickupLocationId,10) > 0;
        }
        return false;
    },

    getSelectedPickupLocationDesc: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isValidPickupLocation(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('pickupLocationDesc') && fulfilmentOptions.pickupLocationDesc !== null ? fulfilmentOptions.pickupLocationDesc : "";
        }
        return "";
    },

    isDineinFulfilmentType: (fulfilmentType, fulfilmentOptions) => {
        return mpoFulfilment.getFulfilmentType(fulfilmentType, fulfilmentOptions) === mpoFulfilment.fulfilmentTypeDinein;
    },

    isTableNumRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDineinFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('table_num_required') && parseInt(selectedFulfilmentType.table_num_required,10) === 1;
        }
        return false;
    },

    isDineinQRCodeScannerEnabled: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isTableNumRequired(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return (selectedFulfilmentType.hasOwnProperty('table_num_qr_scanner') && parseInt(selectedFulfilmentType.table_num_qr_scanner,10) === 1) ||
                    (selectedFulfilmentType.hasOwnProperty('dinein_qr_scanner') && parseInt(selectedFulfilmentType.dinein_qr_scanner,10) === 1);
        }
        return false;
    },

    isValidTableNum: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDineinFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('dineinTableNum') && fulfilmentOptions.dineinTableNum !== null && fulfilmentOptions.dineinTableNum.trim() !== "";
        }
        return false;
    },

    getTableNum: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDineinFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('dineinTableNum') && fulfilmentOptions.dineinTableNum !== null ? fulfilmentOptions.dineinTableNum.trim().toUpperCase() : "";
        }
        return "";
    },

    getTableNumLabel: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDineinFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const fulfilmentTypeSettings = fulfilmentType.fulfilment_types[mpoFulfilment.fulfilmentTypeDinein];
            logger('getTableNumLabel fulfilmentTypeSettings');
            logger(fulfilmentTypeSettings);
            return fulfilmentTypeSettings.hasOwnProperty('table_num_label') && fulfilmentTypeSettings.table_num_label !== null && fulfilmentTypeSettings.table_num_label !== "" ? fulfilmentTypeSettings.table_num_label : "Table";
        }
        return "";
    },

    isBookingFulfilmentType: (fulfilmentType, fulfilmentOptions) => {
        return mpoFulfilment.getFulfilmentType(fulfilmentType, fulfilmentOptions) === mpoFulfilment.fulfilmentTypeBooking;
    },

    isBookingRefRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('booking_ref_required') && parseInt(selectedFulfilmentType.booking_ref_required,10) === 1;
        }
        return false;
    },

    isBookingNameRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('booking_name_required') && parseInt(selectedFulfilmentType.booking_name_required,10) === 1;
        }
        return false;
    },

    isBookingPaxRequired: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return selectedFulfilmentType.hasOwnProperty('booking_pax_required') && parseInt(selectedFulfilmentType.booking_pax_required,10) === 1;
        }
        return false;
    },

    isBookingQRCodeScannerEnabled: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
            return (selectedFulfilmentType.hasOwnProperty('booking_data_qr_scanner') && parseInt(selectedFulfilmentType.booking_data_qr_scanner,10) === 1) ||
                    (selectedFulfilmentType.hasOwnProperty('booking_qr_scanner') && parseInt(selectedFulfilmentType.booking_qr_scanner,10) === 1);
        }
        return false;
    },

    isValidBookingRef: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            if (mpoFulfilment.isBookingRefRequired(fulfilmentType, fulfilmentOptions)) {
                return fulfilmentOptions.hasOwnProperty('dineinBookingRef') && fulfilmentOptions.dineinBookingRef !== null && fulfilmentOptions.dineinBookingRef.trim() !== "";
            }
        }
        return true;
    },

    getBookingRef: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentOptions.hasOwnProperty('dineinBookingRef') && fulfilmentOptions.dineinBookingRef !== null ? fulfilmentOptions.dineinBookingRef.trim().toUpperCase() : "";
        }
        return "";
    },

    getBookingRefLabel: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            const fulfilmentTypeSettings = fulfilmentType.fulfilment_types[mpoFulfilment.fulfilmentTypeBooking];
            return fulfilmentTypeSettings.hasOwnProperty('booking_ref_label') && fulfilmentTypeSettings.booking_ref_label !== null && fulfilmentTypeSettings.booking_ref_label.trim() !== "" ? fulfilmentTypeSettings.booking_ref_label : "Booking";
        }
        return "";
    },

    isCustomFulfilmentType: (fulfilmentType, fulfilmentOptions) => {
        return mpoFulfilment.getFulfilmentType(fulfilmentType, fulfilmentOptions) === mpoFulfilment.fulfilmentTypeCustom;
    },

    isValidDateTime: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.validFulfilment(fulfilmentType, fulfilmentOptions)) {
            if (mpoFulfilment.isDineinFulfilmentType(fulfilmentType, fulfilmentOptions)) {
                return true; // datetime not required
            } else if (mpoFulfilment.isDeliverySlotRequired(fulfilmentType, fulfilmentOptions) ||
                        mpoFulfilment.isDeliveryStopRequired(fulfilmentType, fulfilmentOptions) ||
                        mpoFulfilment.isPickupLocationRequired(fulfilmentType, fulfilmentOptions)) {
                // only date required
                return fulfilmentOptions.datetime !== null; // todo: && fulfilmentOptions.datetime >= unixUtcTimeAtStartOfDayMs()
            } else {
                if (mpoFulfilment.isFulfilmentAsapAnOption(fulfilmentType, fulfilmentOptions) && fulfilmentOptions.asap) {
                    return true; // datetime not required
                } else {
                    return !mpoFulfilment.isFulfilmentDateTimeRequired(fulfilmentType, fulfilmentOptions) || (fulfilmentOptions.datetime !== null && fulfilmentOptions.datetime >= unixUtcTimeMs());
                }
            }
        }
        return false;
    },

    getFulfilmentDesc: (fulfilmentType, fulfilmentOptions) => {

        let fulfilmentDesc = "";
        if (mpoFulfilment.validFulfilment(fulfilmentType, fulfilmentOptions)) {
            fulfilmentDesc = fulfilmentType.desc;
            if (mpoFulfilment.isDineinFulfilmentType(fulfilmentType, fulfilmentOptions)) {
                fulfilmentDesc = "Order for "+mpoFulfilment.getTableNumLabel(fulfilmentType, fulfilmentOptions);
                if (mpoFulfilment.isValidTableNum(fulfilmentType, fulfilmentOptions)) {
                    fulfilmentDesc += " " + mpoFulfilment.getTableNum(fulfilmentType, fulfilmentOptions);
                }
            } else if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
                fulfilmentDesc = "Order for "+mpoFulfilment.getBookingRefLabel(fulfilmentType, fulfilmentOptions);
                if (mpoFulfilment.isValidBookingRef(fulfilmentType, fulfilmentOptions)) {
                    fulfilmentDesc += " " + mpoFulfilment.getBookingRef(fulfilmentType, fulfilmentOptions);
                }
            } else if (mpoFulfilment.isDeliverySlotRequired(fulfilmentType, fulfilmentOptions)) {
                fulfilmentDesc += " "+fulfilmentOptions.datetimeVal+" "+mpoFulfilment.getSelectedDeliverySlotDesc(fulfilmentType, fulfilmentOptions);
            } else if (mpoFulfilment.isDeliveryStopRequired(fulfilmentType, fulfilmentOptions)) {
                fulfilmentDesc += " "+fulfilmentOptions.datetimeVal+" "+mpoFulfilment.getSelectedDeliveryStopDesc(fulfilmentType, fulfilmentOptions);
            } else if (mpoFulfilment.isPickupLocationRequired(fulfilmentType, fulfilmentOptions)) {
                fulfilmentDesc += " "+fulfilmentOptions.datetimeVal+" "+mpoFulfilment.getSelectedPickupLocationDesc(fulfilmentType, fulfilmentOptions);
            } else {
                if (mpoFulfilment.isFulfilmentAsapAnOption(fulfilmentType, fulfilmentOptions) && fulfilmentOptions.asap) {
                    //logger('getFulfilmentDesc');
                    //logger(fulfilmentType);
                    //logger(fulfilmentOptions);
                    fulfilmentDesc += " ASAP";
                    //const selectedFulfilmentType = fulfilmentType.fulfilment_types[fulfilmentOptions.code];
                    //logger(selectedFulfilmentType);
                    if (fulfilmentType.hasOwnProperty('availability')) {
                        //logger(fulfilmentType.availability);
                        const menuAvailability = fulfilmentType.availability;
                        //const asapMinNotice = menuAvailability.online_status === 1 ? selectedFulfilmentType.min_notice : menuAvailability.available_from;
                        const asapMinNotice = menuAvailability.available_from;
                        //const asapMinNoticeUnits = menuAvailability.online_status === 1 ? 'mins' : 'time';
                        const asapMinNoticeUnits = 'time';

                        let text = null;
                        if (menuAvailability.available_today !== 1 && menuAvailability.online_status !== 1) {
                            //return "";
                            text = "unavailable today";
                        } else if (asapMinNotice) {
                            text = "approx "+asapMinNotice;
                            if (asapMinNoticeUnits !== 'time') {
                                text += " "+asapMinNoticeUnits;
                            }
                        }
                        if (text) {
                            fulfilmentDesc += " ("+text+")";
                        }
                    }
                } else if (fulfilmentOptions.datetimeVal !== null) {
                    fulfilmentDesc += " "+fulfilmentOptions.datetimeVal;
                } 
            }
        }
        return fulfilmentDesc;

    },

    getFulfilmentOptionDesc: (fulfilmentType, fulfilmentOptions) => {
        if (mpoFulfilment.isDeliverySlotRequired(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentType.verb + " Slot";
        } else if (mpoFulfilment.isDeliveryStopRequired(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentType.verb + " Stop";
        } else if (mpoFulfilment.isPickupLocationRequired(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentType.verb + " Location";
        } else if (mpoFulfilment.isDineinFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentType.verb + " " + mpoFulfilment.getTableNumLabel(fulfilmentType, fulfilmentOptions);
        } else if (mpoFulfilment.isBookingFulfilmentType(fulfilmentType, fulfilmentOptions)) {
            return fulfilmentType.verb + " Options";
        }
        return fulfilmentType.verb + " Time";
    }

};

