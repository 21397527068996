import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";

class CustomFieldItem extends Component {

    render = () => {
        return (
            <React.Fragment>
                <mobiscroll.Input labelStyle="stacked" value={this.props.value} onChange={this.props.onChangeHandler} name={this.props.field} data-fieldname={this.props.field}>{this.props.settings.label}</mobiscroll.Input>
            </React.Fragment>
        );
    }
}

export default CustomFieldItem;