//import { logger, attachDeviceInfoToData, isCordova } from '../shared/utility';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../components/axios';

import { mpoSentry } from './Sentry';
import {attachDeviceInfoToData, isCordova} from "../shared/utility";
import {mpoOneSignal} from "./OneSignal";

export const mpoAccount = {

    getAccount: (callback, callingComponent = '') => {

        const data = {
            RequestAction: 'CustomerAccount',
            component: callingComponent
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "AUTH") {
                //this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-0)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-0)', 'warning');
            } else if (response.data.ResponseCode === "SUCCESS") {
                /*
                if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                    //this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                    mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                    mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', 'warning');
                }
                */
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], 'warning');
            }
            callback(response);
        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error GA1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

}