import {InlineShareButtons} from "sharethis-reactjs";
import React from "react";
import {isAppModePersonal, isCordova, logger} from "../shared/utility";
import mobiscroll from "@mobiscroll/react";

export const mpoShareThis = {

    getShareThisJsx: () => {

        if (!isAppModePersonal()) {
            return null;
        }

        if (isCordova()) {
            // sharethis not working in cordova apps
            return <div className="mbsc-padding">
                <mobiscroll.Button icon="empty icon fas fa-share" block={true} color="info" onClick={(e) => {
                    navigator.share({
                        title: process.env.REACT_APP_APP_TITLE,
                        text: process.env.REACT_APP_APP_DESCRIPTION,
                        url: process.env.REACT_APP_DYNAMIC_LINK_URL
                    }).then((packageNames) => {
                        if (packageNames.length > 0) {
                            logger("Shared successfully with activity "+packageNames[0]);
                        } else {
                            logger("Share was aborted");
                        }
                    }).catch((err) => {
                        logger("Share failed: "+err.message);
                    });
                }}>Share</mobiscroll.Button>
            </div>
        }

        return <InlineShareButtons
            config={{
                alignment: 'center',  // alignment of buttons (left, center, right)
                color: 'social',      // set the color of buttons (social, white)
                enabled: true,        // show/hide buttons (true, false)
                font_size: 16,        // font size for the buttons
                labels: 'cta',        // button labels (cta, counts, null)
                language: 'en',       // which language to use (see LANGUAGES)
                networks: [           // which networks to include (see SHARING NETWORKS)
                    'whatsapp',
                    'messenger',
                    'facebook',
                    'twitter',
                    'email'
                ],
                padding: 12,          // padding within buttons (INTEGER)
                radius: 4,            // the corner radius on each button (INTEGER)
                show_total: false,
                size: 40,             // the size of each button (INTEGER)


                // OPTIONAL PARAMETERS

                min_count: 10,                    // (threshold for total share count to be displayed)
                url: process.env.REACT_APP_DYNAMIC_LINK_URL, // (defaults to current url)
                image: process.env.REACT_APP_SHARE_IMAGE_URL,  // (defaults to og:image or twitter:image)
                description: process.env.REACT_APP_APP_DESCRIPTION,       // (defaults to og:description or twitter:description)
                title: process.env.REACT_APP_APP_TITLE,            // (defaults to og:title or twitter:title)
                message: process.env.REACT_APP_APP_DESCRIPTION,     // (only for email sharing)
                subject: process.env.REACT_APP_APP_TITLE,  // (only for email sharing)
                username: '' // (only for twitter sharing)
            }}
        />
    }

}