import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    order: { 
        items: []
    },
    merchant: {}, // merchant level settings
    menu: {},     // menu level settings
    customer: {}, // customer settings for merchant, e.g. balance, payment gateway
};

const setCart = ( state, action ) => {
    // data transformations here
    if (Object.keys(action.payload).length === 0) {
        action.payload = initialState;
    }
    const updatedState = action.payload;
    return updateObject( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_CART: return setCart( state, action );
        default: return state;
    }
};

export default reducer;