import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import { mpoAccount } from '../../lib/Account';

import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import {Helmet} from "react-helmet";
import {logger, updateObject} from "../../shared/utility";
import {mpoLinkly} from "../../lib/Linkly";

let retries = 0;

class AccountLinklyPairing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            eftposSettings: {
                is_eftpos_enabled: 0,
                eftpos_provider: "LINKLY",
                pos_guid: "",
                username: "",
                password: "",
                paircode: "",
                secret: "",
                oauth_token: "",
                oauth_expiry: null,
                oauth_refresh_token: "",
                receipt_cut: 0,
                receipt_auto_print: 0
            },
            adminAuthed: false,
            pairing: false,
            statusText: ""
        }

    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Linkly','info');
        mpoAccount.getAccount(this.onGetAccount, 'Linkly');
    }

    onGetAccount = (response) => {

        if (response.data.ResponseCode === "AUTH") {
            this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
        } else if (response.data.ResponseCode === "SUCCESS") {
            if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                this.props.updateStateWithCustomer({id: 0, status: 0}, this.props);
                mobiscroll.toast({message: 'Session expired, sign in to try again (MSG FE-AUTH-2)', color: 'danger'});
                mpoSentry.captureMessage('Session expired (MSG FE-AUTH-2)', 'warning');
            } else {
                this.setState({
                    isLoading: false,
                    eftposSettings: response.data.Response.account.eftpos
                });
                mpoLinkly.Init(response.data.Response.account.eftpos.pos_guid, response.data.Response.account.eftpos.secret,
                    response.data.Response.customer.id, response.data.Response.customer.name,
                    response.data.Response.account.country_code, response.data.Response.account.currency_code);
            }
        } else {
            //response.data.ResponseCode === "ERROR"
        }
    }

    onFieldChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = e.target.value;
        const updatedState = updateObject(this.state.eftposSettings, {
            [fieldName]: fieldValue
        });
        this.setState({eftposSettings: updatedState});
    }

    pairPinpad = () => {
        retries = 0;
        this.setState({
            pairing: true,
            statusText: "Pairing Pinpad (Auth 1 of 2)..."
        });
        if (this.state.eftposSettings.secret === undefined || this.state.eftposSettings.secret === "" || this.state.eftposSettings.secret === null) {
            mpoLinkly.sendPairRequest(this.state.eftposSettings, this.pairRequestCallback);
        } else {
            this.getToken(true);
        }
    }

    pairRequestCallback = (success, status_code, response) => {
        logger("pairRequestCallback called");
        logger(JSON.stringify(response));
        let failed = !success;
        let error_text = failed ? response : ("Status Code "+status_code);
        /* eslint-disable no-fallthrough */
        switch (status_code) {
            case 200:
                retries = 0;
                this.getToken();
                break;
            case 202:
                // shouldn't happen according to docs
                failed = true;
                break;
            case 400:
                // bad request
            case 401:
                // unauthorised (invalid username/password/paircode)
            case 404:
                // original transaction was not successful
                failed = true;
                if (response.hasOwnProperty('responseText') && response.responseText !== undefined && response.responseText !== "") {
                    error_text =  response.responseText.trim();
                }
                break;
            case 408:
                // retry
                if (retries === 0) {
                    retries++;
                    mpoLinkly.sendPairRequest(this.state.eftposSettings, this.pairRequestCallback);
                } else {
                    failed = true;
                }
                break;
            default:
                if (status_code >= 500 && status_code < 600) {
                    if (retries === 0) {
                        retries++;
                        mpoLinkly.sendPairRequest(this.state.eftposSettings, this.pairRequestCallback);
                    } else {
                        failed = true;
                    }
                } else {
                    failed = true;
                }
        }
        /* eslint-enable no-fallthrough */

        if (failed) {
            this.setState({
                pairing: false,
                statusText: "Pair Request Failed (MSG-AL1). " + error_text
            });
            logger("Pair Request Failed (MSG-AL1). " + error_text);
            mpoSentry.captureWarnMessageAll("Pair Request Failed (MSG-AL1). " + error_text);
        }
    }

    getToken = (skipAuth1 = false) => {
        this.setState({
            pairing: true,
            statusText: skipAuth1 ? "Validating Paired Pinpad..." : "Pairing Pinpad (Auth 2 of 2)..."
        });
        mpoLinkly.getToken(this.getTokenCallback);
    }

    getTokenCallback = (success, status_code, response) => {
        logger("getTokenCallback called: "+success+" "+status_code);
        logger(JSON.stringify(response));

        let failed = !success;
        let error_text = failed ? response : ("Status Code "+status_code);
        /* eslint-disable no-fallthrough */
        switch (status_code) {
            case 200:
                retries = 0;
                mpoLinkly.getPinPadStatus(this.getPinPadStatusCallback);
                break;
            case 202:
                // shouldn't happen according to docs
                failed = true;
                break;
            case 400:
                // bad request
            case 401:
                // unauthorised (secret invalid)
            case 404:
                // original transaction was not successful
                failed = true;
                if (response.hasOwnProperty('responseText') && response.responseText !== undefined && response.responseText !== "") {
                    error_text =  response.responseText.trim();
                }
                break;
            case 408:
                // retry
                if (retries === 0) {
                    retries++;
                    mpoLinkly.getToken(this.getTokenCallback);
                } else {
                    failed = true;
                }
                break;
            default:
                if (status_code >= 500 && status_code < 600) {
                    if (retries === 0) {
                        retries++;
                        mpoLinkly.getToken(this.getTokenCallback);
                    } else {
                        failed = true;
                    }
                } else {
                    failed = true;
                }
        }
        /* eslint-enable no-fallthrough */

        if (failed) {
            this.setState({
                pairing: false,
                statusText: "Token Request Failed (MSG-AL2). "+error_text
            });
            logger("Token Request Failed (MSG-AL2). " + error_text);
            mpoSentry.captureWarnMessageAll("Token Request Failed (MSG-AL2). " + error_text);
        }
    }

    getPinPadStatusCallback = (success, status_code, response) => {
        logger("getPinPadStatusCallback called");
        logger(JSON.stringify(response));

        let failed = !success;
        let error_text = failed ? response : ("Status Code "+status_code);
        /* eslint-disable no-fallthrough */
        switch (status_code) {
            case 200:
                retries = 0;
                // update redux with secret etc
                const eftpos = updateObject(this.state.eftposSettings, {
                    password: "",
                    paircode: "",
                    secret: mpoLinkly._secret,
                    oauth_token: mpoLinkly._token,
                    oauth_expiry: mpoLinkly._token_expiry,
                });
                this.props.updateStateWithEftpos(eftpos);

                const txnInProgress = mpoLinkly.getTransactionCookie();
                let redirectUrl = '/menu/' + this.props.user.customer.menu_name;
                if (txnInProgress && txnInProgress !== undefined && this.props.order !== undefined && this.props.order.hasOwnProperty('num_items') && this.props.order.num_items) {
                    // redirect to checkout to continue order
                    redirectUrl = '/checkout';
                }
                this.props.history.push(redirectUrl);
                break;
            case 202:
                // shouldn't happen according to docs (async only)
                failed = true;
                break;
            case 400:
                // bad request
            case 401:
                // unauthorised (invalid token)
            case 404:
                // original transaction was not successful
                failed = true;
                if (response.hasOwnProperty('responseText') && response.responseText !== undefined && response.responseText !== "") {
                    error_text =  response.responseText.trim();
                }
                break;
            case 408:
                // retry
                if (retries === 0) {
                    retries++;
                    mpoLinkly.getPinPadStatus(this.getPinPadStatusCallback);
                } else {
                    failed = true;
                }
                break;
            default:
                if (status_code >= 500 && status_code < 600) {
                    if (retries === 0) {
                        retries++;
                        mpoLinkly.getPinPadStatus(this.getPinPadStatusCallback);
                    } else {
                        failed = true;
                    }
                } else {
                    failed = true;
                }
        }
        /* eslint-enable no-fallthrough */

        if (failed) {
            // reset secret here to allow username/password entry when 401 error
            const updatedState = updateObject(this.state.eftposSettings, {
                secret: ""
            });
            this.setState({
                pairing: false,
                eftposSettings: updatedState,
                statusText: "Pinpad Status Request Failed (MSG-AL3). "+error_text
            });
            logger("Pinpad Status Request Failed (MSG-AL3). " + error_text);
            mpoSentry.captureWarnMessageAll("Pinpad Status Request Failed (MSG-AL3). " + error_text);
        }
    }

    render = () => {

        if (this.state.isLoading) {
            return (
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        }

        return (
            <React.Fragment>
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab">
                        <Helmet>
                            <title>{`${process.env.REACT_APP_APP_TITLE} Linkly`}</title>
                        </Helmet>

                        {this.props.user.auth.isKiosk || this.props.user.auth.isStaff ?
                            <div className="mbsc-form-group">
                                {this.state.eftposSettings.secret === undefined || this.state.eftposSettings.secret === "" || this.state.eftposSettings.secret === null ?
                                <React.Fragment>
                                    <div className="mbsc-form-group-title">Pair Eftpos Pinpad</div>
                                    {this.state.eftposSettings.pos_guid === undefined || this.state.eftposSettings.pos_guid === "" || this.state.eftposSettings.pos_guid === null ?
                                    <div className="mbsc-padding mbsc-txt-m">POS ID needs to be assigned to this user by an Admin before pairing the Eftpos pinpad.</div>
                                    :
                                    <React.Fragment>
                                        <div className="mbsc-padding mbsc-txt-muted">POS ID: {this.state.eftposSettings.pos_guid}</div>
                                        <mobiscroll.Input labelStyle="stacked"
                                                          value={this.state.eftposSettings.username}
                                                          onChange={this.onFieldChange} data-fieldname="username"
                                                          autoComplete="off">Linkly Username</mobiscroll.Input>
                                        <mobiscroll.Input labelStyle="stacked" value={this.state.eftposSettings.password} type="password" passwordToggle={true} onChange={this.onFieldChange} data-fieldname="password" autoComplete="off">Linkly Password</mobiscroll.Input>
                                        <mobiscroll.Input labelStyle="stacked" value={this.state.eftposSettings.paircode} onChange={this.onFieldChange} data-fieldname="paircode" autoComplete="off">Pair Code</mobiscroll.Input>
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="mbsc-form-group-title">Eftpos Pinpad Paired</div>
                                    <div className="mbsc-padding mbsc-txt-muted">POS ID: {this.state.eftposSettings.pos_guid}</div>
                                    <div className="mbsc-padding mbsc-txt">Ensure the Eftpos Pinpad is switched on and connected, then click Proceed.</div>
                                </React.Fragment>
                                }

                                <div className="mbsc-padding" style={{paddingTop: 0}}>
                                    {this.state.eftposSettings.pos_guid === undefined || this.state.eftposSettings.pos_guid === "" || this.state.eftposSettings.pos_guid === null ?
                                        <React.Fragment>
                                        {this.props.hasOwnProperty('history') ? <button onClick={() => {
                                                this.props.history.push('/logout');
                                            }}>Logout to restart pairing</button> : null}
                                        </React.Fragment>
                                        :
                                        <mobiscroll.Button block={true} disabled={this.state.pairing} color="success"
                                                           style={{color: '#fff', textTransform: "capitalize"}}
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               this.pairPinpad();
                                                           }}>{this.state.eftposSettings.secret === undefined || this.state.eftposSettings.secret === "" || this.state.eftposSettings.secret === null ? "Pair Pinpad" : "Proceed"}</mobiscroll.Button>
                                    }
                                </div>


                                <div>
                                    <p style={{textAlign: "center"}}>{this.state.statusText}</p>
                                </div>

                            </div>
                            : null}
                    </div>
                </mobiscroll.Form>
            </React.Fragment>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        order: state.menu.order,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps, redirect) => {
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        },
        updateStateWithEftpos: (eftpos) => {
            dispatch(actions.setEftposAction(eftpos));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountLinklyPairing));
