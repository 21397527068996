import * as actionTypes from './actionTypes';

export const saveCartAction = ( cart ) => {
    return {
        type: actionTypes.SET_CART,
        payload: cart
    };
}

export const setCartAction = ( cart ) => {
    return dispatch => {
        // async code here
        setTimeout(() => {
            dispatch(saveCartAction(cart));
        }, 0);
    }
};
