import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import { updateObject } from '../../shared/utility';

class DeliverySlot extends Component {

    constructor (props) {
        super(props);
        //logger("Account/DeliverySlot");
        //logger(props);

        this.state = {
            delivery: this.props.delivery,
            //delivery_slot_id: this.props.delivery_slot_id,
        }

        this.handleChangeDeliverySlot = this.handleChangeDeliverySlot.bind(this);
    }

    handleChangeDeliverySlot = () => {
        // console.log("Account/DeliverySlot.handleChangeDeliverySlot");
        // console.log(this.state);
        if (this.props.handleChangeDeliverySlot !== undefined && typeof(this.props.handleChangeDeliverySlot) === 'function') {
            this.props.handleChangeDeliverySlot(this.state);
        }
    }

    componentDidUpdate = (prevProps) => {
        //logger('Account/DeliverySlot componentDidUpdate');
        // if (prevProps.delivery.delivery_slot_id !== this.props.delivery.delivery_slot_id && this.props.delivery.delivery_slot_id > 0) {
        //     this.getDeliverySlots();
        // }
    }

    radioChange = (event) => {
        //logger(event);
        this.setDeliverySlotId(parseInt(event.target.value,10));
    }

    setDeliverySlotId = (id) => {
        this.setState({
            delivery: updateObject(this.state.delivery, {
                delivery_slot_id: id,
            })
        }, this.handleChangeDeliverySlot);
    }

    getDeliverySlotsJsx = () => {

        if (this.state.delivery.delivery_slots.length > 0) {

            return this.state.delivery.delivery_slots.map(slot => {
                return <mobiscroll.Radio key={slot.delivery_slot_id}
                                         value={slot.delivery_slot_id}
                                         checked={parseInt(this.state.delivery.delivery_slot_id, 10) === parseInt(slot.delivery_slot_id, 10)}
                                         onChange={this.radioChange}>
                    {slot.display_label}
                    <span className="mbsc-desc">{slot.available_str}</span>
                </mobiscroll.Radio>
            });

        } else {

            return <mobiscroll.Note color="danger" className="mbsc-align-center">No slots available</mobiscroll.Note>

        }

    }

    render = () => {

        const deliverySlotsJsx = this.getDeliverySlotsJsx();

        return (
            <React.Fragment>
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <span className="mbsc-txt-s">Delivery Slot</span>
                </div>
                {deliverySlotsJsx}
            </React.Fragment>
        )

    }

}

export default DeliverySlot;

