import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import ContentLoader from "react-content-loader"

const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
const isSingleStore = isCustomApp && process.env.REACT_APP_SINGLE_STORE === 'true';
const displaySuburbName = process.env.REACT_APP_DISPLAY_SUBURB_NAME === 'true';

class MerchantCardSimple extends Component {

    constructor (props) {
        super(props);
        this.state = {
            heroImage: true
        }
    }

    render = () => {
        //logger(this.props);

        if (this.props.item.hasOwnProperty('merchant_placeholder')) {
            return (
                <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-4 mbsc-col-xl-4">
                    <mobiscroll.Card className="merchant-card-simple" style={{margin: "0.5em"}}>
                        <ContentLoader
                            height={50}
                            width={400}
                            speed={2}
                            primaryColor="#f3f3f3"
                            secondaryColor="#a8a8a8">
                            <rect x="70" y="15" rx="4" ry="4" width="250" height="6" />
                        </ContentLoader>
                    </mobiscroll.Card>
                </div>
            )
        }

        const isActive = this.props.item.status === 'online' || this.props.item.status === 'offline';
        let cardClassNames = "merchant-card-simple merchant-card-simple-";
        if (isActive) {
            cardClassNames += "active";
        } else {
            cardClassNames += "inactive";
        }

        return (
            <div className="mbsc-col-12 mbsc-col-xs-12 mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-lg-4 mbsc-col-xl-4">
                <mobiscroll.Card className={cardClassNames} style={{margin: "0.5em", cursor: "pointer"}} onClick={(e) => { e.preventDefault(); if (isActive) this.props.history.push('/menu/'+this.props.item.menus[0].name) }}>
                    <mobiscroll.CardContent>
                        <mobiscroll.CardTitle>{this.props.item.name} {isCustomApp && !isSingleStore && displaySuburbName ? this.props.item.suburb : null}</mobiscroll.CardTitle>
                    </mobiscroll.CardContent>
                </mobiscroll.Card>
            </div>
        );
    }
}

export default withRouter(MerchantCardSimple);