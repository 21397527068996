import { logger } from '../shared/utility';

import { mpoSentry } from './Sentry';

export const mpoCoinbase = {
    _inapp_browser: null,
    _inapp_browser_result: null,
    
    _in_app_browser_load_error: function(event) {
        logger('mpoCoinbase._in_app_browser_load_error() called');
        logger(event);
        mpoSentry.captureMessage(event.message, 'warning');
        //if (ConfigGlobal.Debug()) { displayAlert(event.message, 'Load Error'); }
    },

    _in_app_browser_load_stop: function(event) {
        logger('mpoCoinbase._in_app_browser_load_stop() called');
        logger(event);
        if (event.url.indexOf('coinbase_success.php') >= 0) {
            //set some kind of topup result flag and show success after close browser
            mpoCoinbase._inapp_browser_result = 'cb_success';
            if (mpoCoinbase._inapp_browser) mpoCoinbase._inapp_browser.close();
        } else if (event.url.indexOf('coinbase_cancel.php') >= 0) {
            //set some kind of topup result flag and display result
            mpoCoinbase._inapp_browser_result = 'cb_cancel';
            if (mpoCoinbase._inapp_browser) mpoCoinbase._inapp_browser.close();
        } else if (event.url.indexOf('coinbase_error.php') >= 0) {
            //set some kind of topup result flag and display result
            mpoCoinbase._inapp_browser_result = 'cb_error';
            if (mpoCoinbase._inapp_browser) mpoCoinbase._inapp_browser.close();
        }
    },

    _in_app_browser_exit: function(event) {
        // use as template:
        logger('mpoCoinbase._in_app_browser_exit() called');
        logger(event);
        mpoCoinbase._inapp_browser.removeEventListener('loadstop', mpoCoinbase._in_app_browser_load_stop);
        mpoCoinbase._inapp_browser.removeEventListener('loaderror', mpoCoinbase._in_app_browser_load_error);
        mpoCoinbase._inapp_browser.removeEventListener('exit', mpoCoinbase._in_app_browser_exit);
        mpoCoinbase._inapp_browser = null;

        logger('mpoCoinbase._inapp_browser_result = ' + mpoCoinbase._inapp_browser_result);
        switch (mpoCoinbase._inapp_browser_result) {
            case 'cb_success':
                //all good, show success
                //mobiscroll.toast({message: 'Thank you for your payment. You will receive an email once the payment has been verified on the blockchain.', color: 'success'});
                //this.getAccount();
                break;
            case 'cb_cancel':
                //nothing to do, user knows they cancelled
                //mpoSentry.captureMessage('Action cancelled', 'warning');
                //mobiscroll.toast({message: 'Action cancelled', color: 'info'});
                break;
            case 'cb_error':
                //fetch and display error to user
                //var msg = "An error occurred";
                //mpoSentry.captureMessage(msg, 'warning');
                //mobiscroll.toast({message: msg, color: 'error'});
                break;
            default:
                //var msg = "An unknown result was specified";
                //mpoSentry.captureMessage(msg, 'warning');
                //mobiscroll.toast({message: msg, color: 'error'});
        }
        mpoCoinbase._inapp_browser_result = null;
        //reset payment progress state
    }

}