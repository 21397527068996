import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import {updateObject} from "../../shared/utility";
//import { logger } from '../../shared/utility';

class StandingOrderProductItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: false,
            item: this.props.item
        };
        this.onChangeQty = this.onChangeQty.bind(this);
        this.handleChangeProductQty = this.handleChangeProductQty.bind(this);
        this.handleLoadModifiers = this.handleLoadModifiers.bind(this);
    }

    handleChangeProductQty = () => {
        //console.log("StandingOrderProductItem.handleChangeProductQty");
        //console.log(this.state);
        if (this.props.handleChangeProductQty !== undefined && typeof(this.props.handleChangeProductQty) === 'function') {
            this.props.handleChangeProductQty(this.state);
        }
    }

    onChangeQty = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = e.target.value;
        //logger('onCheckoutChange '+fieldName+'='+fieldValue);
        this.setState({
            item: updateObject(this.state.item, {
                [fieldName]: fieldValue
            })
        }, this.handleChangeProductQty);
    }

    handleLoadModifiers = () => {
        //console.log("StandingOrderProductItem.handleChangeProductQty");
        //console.log(this.state);
        if (this.props.handleLoadModifiers !== undefined && typeof(this.props.handleLoadModifiers) === 'function') {
            if (parseInt(this.state.item.options_available,10) === 1) {
                this.props.handleLoadModifiers(this.state.item.menu_item_id, this.state.item.options);
            }
        }
    }

    getNumDaysAvailable = () => {
        let num = 0;
        if (this.props.menuAvailability.available_mon) num++;
        if (this.props.menuAvailability.available_tue) num++;
        if (this.props.menuAvailability.available_wed) num++;
        if (this.props.menuAvailability.available_thu) num++;
        if (this.props.menuAvailability.available_fri) num++;
        if (this.props.menuAvailability.available_sat) num++;
        if (this.props.menuAvailability.available_sun) num++;
        return num;
    }

    render = () => {
        //logger(this.props);

        const showUnavailableDays = this.props.showUnavailableDays;

        let dayClassNames = "mbsc-col-lg-1 mbsc-col-md-1 mbsc-col-sm-3 mbsc-col-3"; // 6-7 days
        if (!showUnavailableDays) {
            const numDaysAvailable = this.getNumDaysAvailable();
            if (numDaysAvailable < 5) {
                dayClassNames = "mbsc-col-lg-2 mbsc-col-md-2 mbsc-col-sm-2 mbsc-col-3";
            } else if (numDaysAvailable < 6) {
                dayClassNames = "mbsc-col-lg-1 mbsc-col-md-1 mbsc-col-sm-2 mbsc-col-3";
            }
        }

        return (
            <div key={this.props.item.menu_item_id} className="mbsc-row mbsc-align-items-center mbsc-txt-s">
                <div className="mbsc-col-lg-3 mbsc-col-md-3 mbsc-col-sm-6 mbsc-col-6">{this.props.item.menu_item_desc} {parseInt(this.state.item.options_available,10) === 1 ? <span>(<a href="#" onClick={(e) => {e.preventDefault(); this.handleLoadModifiers();}}>options</a>)</span> : null }</div>
                <div className="mbsc-col-lg-1 mbsc-col-md-1 mbsc-col-sm-6 mbsc-col-6 mbsc-align-right">{this.props.locale.currency_sign}{this.props.item.price}</div>
                {showUnavailableDays || this.props.menuAvailability.available_mon ? <div className={dayClassNames} style={{paddingRight:0}}><mobiscroll.Input type="number" style={{width:"50px",maxWidth:"50px",paddingRight:0}} disabled={!this.props.menuAvailability.available_mon} inputStyle="" labelStyle="stacked" placeholder="" min="0" max="999" maxLength="3" size="3" data-fieldname="qty_mon" value={this.state.item.qty_mon === "0" ? "" : this.state.item.qty_mon} onChange={this.onChangeQty}>Mon</mobiscroll.Input></div> : null }
                {showUnavailableDays || this.props.menuAvailability.available_tue ? <div className={dayClassNames} style={{paddingRight:0}}><mobiscroll.Input type="number" style={{width:"50px",maxWidth:"50px",paddingRight:0}} disabled={!this.props.menuAvailability.available_tue} inputStyle="" labelStyle="stacked" placeholder="" min="0" max="999" maxLength="3" size="3" data-fieldname="qty_tue" value={this.state.item.qty_tue === "0" ? "" : this.state.item.qty_tue} onChange={this.onChangeQty}>Tue</mobiscroll.Input></div> : null }
                {showUnavailableDays || this.props.menuAvailability.available_wed ? <div className={dayClassNames} style={{paddingRight:0}}><mobiscroll.Input type="number" style={{width:"50px",maxWidth:"50px",paddingRight:0}} disabled={!this.props.menuAvailability.available_wed} inputStyle="" labelStyle="stacked" placeholder="" min="0" max="999" maxLength="3" size="3" data-fieldname="qty_wed" value={this.state.item.qty_wed === "0" ? "" : this.state.item.qty_wed} onChange={this.onChangeQty}>Wed</mobiscroll.Input></div> : null }
                {showUnavailableDays || this.props.menuAvailability.available_thu ? <div className={dayClassNames} style={{paddingRight:0}}><mobiscroll.Input type="number" style={{width:"50px",maxWidth:"50px",paddingRight:0}} disabled={!this.props.menuAvailability.available_thu} inputStyle="" labelStyle="stacked" placeholder="" min="0" max="999" maxLength="3" size="3" data-fieldname="qty_thu" value={this.state.item.qty_thu === "0" ? "" : this.state.item.qty_thu} onChange={this.onChangeQty}>Thu</mobiscroll.Input></div> : null }
                {showUnavailableDays || this.props.menuAvailability.available_fri ? <div className={dayClassNames} style={{paddingRight:0}}><mobiscroll.Input type="number" style={{width:"50px",maxWidth:"50px",paddingRight:0}} disabled={!this.props.menuAvailability.available_fri} inputStyle="" labelStyle="stacked" placeholder="" min="0" max="999" maxLength="3" size="3" data-fieldname="qty_fri" value={this.state.item.qty_fri === "0" ? "" : this.state.item.qty_fri} onChange={this.onChangeQty}>Fri</mobiscroll.Input></div> : null }
                {showUnavailableDays || this.props.menuAvailability.available_sat ? <div className={dayClassNames} style={{paddingRight:0}}><mobiscroll.Input type="number" style={{width:"50px",maxWidth:"50px",paddingRight:0}} disabled={!this.props.menuAvailability.available_sat} inputStyle="" labelStyle="stacked" placeholder="" min="0" max="999" maxLength="3" size="3" data-fieldname="qty_sat" value={this.state.item.qty_sat === "0" ? "" : this.state.item.qty_sat} onChange={this.onChangeQty}>Sat</mobiscroll.Input></div> : null }
                {showUnavailableDays || this.props.menuAvailability.available_sun ? <div className={dayClassNames} style={{paddingRight:0}}><mobiscroll.Input type="number" style={{width:"50px",maxWidth:"50px",paddingRight:0}} disabled={!this.props.menuAvailability.available_sun} inputStyle="" labelStyle="stacked" placeholder="" min="0" max="999" maxLength="3" size="3" data-fieldname="qty_sun" value={this.state.item.qty_sun === "0" ? "" : this.state.item.qty_sun} onChange={this.onChangeQty}>Sun</mobiscroll.Input></div> : null }
            </div>
        );
    }

}

export default StandingOrderProductItem;