import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    group: "",
    merchants: [], // all / custom app
    merchants_nearby: [],
    merchants_search: [],
    merchants_fave: [],
    merchants_history: [],
    merchant_types: [],
    merchant_groups: [],
    settings: {}
};

const setLocationGroup = ( state, action ) => {
    const updatedState = {
        group: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchants = ( state, action ) => {
    const updatedState = {
        merchants: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchantsNearby = ( state, action ) => {
    const updatedState = {
        merchants_nearby: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchantsSearch = ( state, action ) => {
    const updatedState = {
        merchants_search: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchantsFave = ( state, action ) => {
    const updatedState = {
        merchants_fave: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchantsHistory = ( state, action ) => {
    const updatedState = {
        merchants_history: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchantTypes = ( state, action ) => {
    const updatedState = {
        merchant_types: action.payload
    }
    return updateObject( state, updatedState );
};

const setMerchantGroups = ( state, action ) => {
    const updatedState = {
        merchant_groups: action.payload
    }
    return updateObject( state, updatedState );
};

const setLocationSettings = ( state, action ) => {
    const updatedState = {
        settings: action.payload
    }
    return updateObject( state, updatedState );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_LOCATION_GROUP: return setLocationGroup( state, action );
        case actionTypes.SET_MERCHANTS: return setMerchants( state, action );
        case actionTypes.SET_MERCHANTS_NEARBY: return setMerchantsNearby( state, action );
        case actionTypes.SET_MERCHANTS_SEARCH: return setMerchantsSearch( state, action );
        case actionTypes.SET_MERCHANTS_FAVE: return setMerchantsFave( state, action );
        case actionTypes.SET_MERCHANTS_HISTORY: return setMerchantsHistory( state, action );
        case actionTypes.SET_MERCHANT_TYPES: return setMerchantTypes( state, action );
        case actionTypes.SET_MERCHANT_GROUPS: return setMerchantGroups( state, action );
        case actionTypes.SET_LOCATION_SETTINGS: return setLocationSettings( state, action );
        default: return state;
    }
};

export default reducer;