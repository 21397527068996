import React, { Component } from 'react';
//import { logger } from '../../shared/utility';

class OrderHistoryItem extends Component {

    render = () => {
        //logger(this.props);

        return (
            <li key={this.props.item.id}>
                <h3 className="mpo-product-head">{this.props.item.store_name} {this.props.item.store_suburb}</h3>
                <span className="mbsc-desc mpo-product-desc">Order #{this.props.item.id}</span>
                <span className="mbsc-desc mpo-product-desc">Ordered: {this.props.item.submitted}</span>
                <span className="mbsc-desc mpo-product-desc">Requested for: {this.props.item.requested_for}</span>
            </li>
        );
    }

}

export default OrderHistoryItem;