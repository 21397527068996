import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../../components/axios';
import {updateObject, logger, isGoogleMapsLoaded, attachDeviceInfoToData, isCordova} from '../../shared/utility';

import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {mpoOneSignal} from "../../lib/OneSignal";

class DeliveryAddress extends Component {

    constructor (props) {
        super(props);
        //logger("Checkout/DeliveryAddress");
        //logger(props);
        //logger(this.props.merchant);

        const countryCodeOrCodes = this.props.merchant.hasOwnProperty('locale') && this.props.merchant.locale &&
                                    this.props.merchant.locale.hasOwnProperty('country_code') && this.props.merchant.locale.country_code ?
                                    this.props.merchant.locale.country_code : ['AU','NZ'];

        const placesSearchOptions = {
            componentRestrictions: {'country': countryCodeOrCodes},
            types: ['address']
        };

        /*eslint-disable no-undef*/
        const location = isGoogleMapsLoaded() &&
            this.props.merchant.hasOwnProperty('location') && this.props.merchant.location &&
            this.props.merchant.location.hasOwnProperty('lat') && this.props.merchant.location.lat &&
            this.props.merchant.location.lat !== '' ? new google.maps.LatLng(this.props.merchant.location.lat, this.props.merchant.location.long) : null;
        /*eslint-enable no-undef*/

        if (location !== null) {
            placesSearchOptions['location'] = location;
            const radiusKm = this.props.merchant.location.hasOwnProperty('radius') ? parseInt(this.props.merchant.location.radius,10) : 100;
            placesSearchOptions['radius'] = radiusKm * 1000; // delivery addresses within 100km
        }

        this.state = {
            placesSearchOptions: placesSearchOptions,
            deliveryAddressManual: (this.props.user.checkout.delivery_address === "" && this.props.user.checkout.delivery_postcode !== "") || (this.props.user.checkout.delivery_address !== "" && this.props.user.checkout.delivery_postcode === ""),
            deliveryMins: 0
        }
    }

    componentDidMount = () => {
        const showDeliveryAddressDropdown = this.props.user.auth.isLoggedIn && this.props.user.customer.delivery_addresses.length > 0;
        const showDeliveryAddressLookup = !showDeliveryAddressDropdown || this.props.user.checkout.delivery_address_id === 0;
        const showDeliveryAddressManualEntry = showDeliveryAddressLookup && (this.state.deliveryAddressManual || (this.props.user.checkout.delivery_address === "" && this.props.user.checkout.delivery_postcode !== ""));

        if (showDeliveryAddressDropdown && !showDeliveryAddressManualEntry && this.props.user.checkout.delivery_address === "") {
            this.updateStateWithDeliveryAddressId(parseInt(this.props.user.customer.delivery_addresses[0].id,10));
        }
    }

    setDeliveryAddressId = (event, inst) => {
        this.updateStateWithDeliveryAddressId(parseInt(inst.getVal(),10));
    }

    updateStateWithDeliveryAddressId = (deliveryAddressId) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            delivery_address_id: deliveryAddressId,
            delivery_validated: false,
            delivery_postcode_id: 0,
        });
        // this.updateCheckout to validate address and get delivery fee
        this.updateCheckout({'delivery_address_id': deliveryAddressId});
    }

    onDeliveryAddressChange = (address) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            delivery_address: address,
            delivery_geo: {},
            delivery_latlng: {},
            delivery_address_id: 0,
            delivery_address1: "",
            delivery_address2: "",
            delivery_city: "",
            delivery_state: "",
            delivery_postcode: "",
            delivery_country: "",
            delivery_validated: false,
            delivery_postcode_id: 0,
        });
    }

    onDeliveryAddressManualChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = fieldName === 'delivery_state' ? e.target.value.toUpperCase() : e.target.value;
        //logger('onDeliveryAddressManualChange '+fieldName+'='+fieldValue);
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            delivery_address_id: 0,
            delivery_address: "",
            delivery_geo: {},
            delivery_latlng: {},
            delivery_country: "",
            delivery_validated: false,
            delivery_postcode_id: 0,
            [fieldName]: fieldValue
        });
    }

    onDeliveryAddressSelect = (address) => {
        //logger(address);
        this.onDeliveryAddressChange(address);
        if (address === null || address === "") {
            return;
        }
        geocodeByAddress(address)
            .then(results => {
                //console.log('geo', results); 
                const addr = {
                    delivery_address_id: 0,
                    delivery_address1: "",
                    delivery_address2: "",
                    delivery_city: "",
                    delivery_state: "",
                    delivery_postcode: "",
                    delivery_country: "",
                    delivery_geo: {},
                    delivery_latlng: {},
                    delivery_validated: false,
                    delivery_postcode_id: 0,
                };
                if (results !== undefined && results[0] !== undefined) {
                    addr.delivery_geo = results[0];
                    let has_street_num = false;
                    let has_postcode = false;
                    if (results[0].address_components !== undefined) {
                        for (let a of results[0].address_components) {
                            for(var i = 0; i < a['types'].length; i++) {
                                /* eslint-disable no-fallthrough */
                                switch (a['types'][i]) {
                                    case 'street_number':
                                        has_street_num = true;
                                    case 'route':
                                        if (addr.delivery_address1 !== "") {
                                            addr.delivery_address1 += " ";
                                        }
                                        addr.delivery_address1 += a['short_name'];
                                        break;
                                    case 'locality':
                                        addr.delivery_city = a['short_name'];
                                        break;
                                    case 'administrative_area_level_1':
                                        addr.delivery_state = a['short_name'];
                                        break;
                                    case 'postal_code':
                                        has_postcode = true;
                                        addr.delivery_postcode = a['short_name'];
                                        break;
                                    case 'country':
                                        addr.delivery_country = a['short_name'];
                                        break;
                                    default:
                                }
                                /* eslint-enable no-fallthrough */
                            }
                        }
                    }
                    if (!has_street_num) {
                        //logger('NO STREET NUMBER');
                        //logger('daddr1: '+addr.delivery_address1);
                        //logger('daddrStr: '+address);
                        mpoSentry.captureMessage('NO STREET NUMBER', 'warning');
                        mobiscroll.toast({message: "Error confirming street address, please enter it manually", duration: 6000, color: 'danger'});
                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateDeliveryAddressManual(true);
                    } else if (!has_postcode) {
                        //logger('NO POST CODE');
                        //logger('daddr1: '+addr.delivery_address1);
                        //logger('daddrStr: '+address);
                        mpoSentry.captureMessage('NO POST CODE', 'warning');
                        mobiscroll.toast({message: "Error confirming address, please enter it manually", duration: 6000, color: 'danger'});
                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateDeliveryAddressManual(true);
                    } else {
                        // if billing address not yet entered then set to same as delivery address
                        let updateBillingAddress = false;
                        if (this.props.user.checkout.billing_address === "" && this.props.user.checkout.billing_address1 === "" && this.props.user.checkout.billing_address_id <= 0) {
                            updateBillingAddress = true;
                            addr.billing_address = address;
                            addr.billing_geo = addr.delivery_geo;
                            addr.billing_latlng = {};
                            addr.billing_address1 = addr.delivery_address1;
                            addr.billing_address2 = addr.delivery_address2;
                            addr.billing_city = addr.delivery_city;
                            addr.billing_state = addr.delivery_state;
                            addr.billing_postcode = addr.delivery_postcode;
                            addr.billing_country = addr.delivery_country;
                        }

                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        
                        // this.updateCheckout to validate address and get delivery fee
                        this.updateCheckout({'delivery_address': addr.delivery_address1+','+addr.delivery_address2+','+addr.delivery_city+','+addr.delivery_state+','+addr.delivery_postcode+','+addr.delivery_country});
                        
                        getLatLng(results[0])
                            .then(latLng => {
                                //console.log('Success2', latLng);
                                const updatedState = {
                                    delivery_latlng: latLng
                                }
                                if (updateBillingAddress) {
                                    updatedState.billing_latlng = latLng;
                                }
                                this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, updatedState));
                            })
                            .catch(error => {
                                //console.error('Error2', error)
                                mpoSentry.captureException(error);
                            });
                    }
                }
            })
            .catch(error => {
                //console.error('Error1', error)
                mpoSentry.captureException(error);
                mobiscroll.toast({message: "Error searching for address. Please enter your delivery address manually.", duration: 6000, color: 'danger'});
                this.updateDeliveryAddressManual(true);
            });
    }

    updateDeliveryAddressManual = (val) => {
        const updatedState = updateObject(this.state, {
            deliveryAddressManual: val
        });
        this.setState(updatedState);
    }

    validateAddress = () => {
        // this.updateCheckout to validate address and get delivery fee
        const checkout = this.props.user.checkout;
        this.updateCheckout({'delivery_address': checkout.delivery_address1+','+checkout.delivery_address2+','+checkout.delivery_city+','+checkout.delivery_state+','+checkout.delivery_postcode+','+checkout.delivery_country});
    }

    updateCheckout = (checkout_options) => {

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_checkout',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            checkout_options: checkout_options,
            component: 'Checkout/DeliveryAddress'
        };
        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //console.log('updateCheckout', data);
        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            let validAddress = false;
            let delivery_postcode_id = 0;
            if (response.data.ResponseCode === "SUCCESS") {
                if (response.data.Response.hasOwnProperty('additional_data') && 
                    response.data.Response.additional_data.hasOwnProperty('delivery_address') &&
                    response.data.Response.additional_data.delivery_address.hasOwnProperty('postcode_id')) {
                        delivery_postcode_id = parseInt(response.data.Response.additional_data.delivery_address.postcode_id,10);
                        validAddress = delivery_postcode_id > 0;
                }
                if (response.data.Response.hasOwnProperty('additional_data') &&
                    response.data.Response.additional_data.hasOwnProperty('delivery_mins')) {
                    const updatedState = updateObject(this.state, {
                        deliveryMins: parseInt(response.data.Response.additional_data.delivery_mins,10)
                    });
                    this.setState(updatedState);
                }
                if (parseInt(response.data.Response.affects_cart_total, 10) === 1) {
                    if (this.props.reloadCart !== undefined && typeof(this.props.reloadCart) === 'function') {
                        this.props.reloadCart();
                    }
                }
            } else {
                let errorMsg = response.data.Response[0];
                mobiscroll.toast({message: errorMsg, color: 'danger'});
                mpoSentry.captureMessage(errorMsg, 'warning');

                if (errorMsg.includes('Session expired')) {
                    this.props.updateStateWithCustomer({id: 0, status: 0});
                }
            }
    
            const updatedState = {
                delivery_validated: validAddress,
                delivery_postcode_id: delivery_postcode_id
            }
            this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, updatedState));
        })
        .catch(error => {
            logger(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    render = () => {

        let deliveryAddressDropdown = null;
        const showDeliveryAddressDropdown = this.props.user.auth.isLoggedIn && this.props.user.customer.delivery_addresses.length > 0;
        const showDeliveryAddressLookup = !showDeliveryAddressDropdown || this.props.user.checkout.delivery_address_id === 0;
        const showDeliveryAddressManualEntry = showDeliveryAddressLookup && (this.state.deliveryAddressManual || (this.props.user.checkout.delivery_address === "" && this.props.user.checkout.delivery_postcode !== ""));

        if (showDeliveryAddressDropdown) {
            deliveryAddressDropdown = <label>
                Delivery address
                <mobiscroll.Select
                    select="single"
                    value={this.props.user.checkout.delivery_address_id}
                    placeholder="Choose address or enter new one"
                    labelStyle="stacked"
                    onSet={this.setDeliveryAddressId}
                >
                    <option key={0} value={0}>New delivery address</option>
                    {this.props.user.customer.delivery_addresses.map((addr, idx) => <option key={addr.id} value={addr.id}>{addr.address}</option>)}
                </mobiscroll.Select>
            </label>
        }

        let bodyText = this.props.bodyText;
        if (bodyText) {
            let deliveryEstimate = '';
            if (this.state.deliveryMins > 0) {
                deliveryEstimate = 'Estimated delivery time is currently '+this.state.deliveryMins+' minutes.';
            }
            bodyText = bodyText.replace('|deliveryEstimate|', deliveryEstimate);
        }

        return (
            <mobiscroll.FormGroup inset className="mpo-checkout-form">

                {!this.props.hasOwnProperty('showHeader') || this.props.showHeader ?
                <div className="mbsc-form-group-title">{this.props.headerText ? this.props.headerText : "Delivery"}</div>
                    : null}

                {!this.props.hasOwnProperty('showCustomerInfo') || this.props.showCustomerInfo ?
                    <React.Fragment>
                        {this.props.user.auth.isLoggedIn ?
                            <div className="mbsc-padding" style={{paddingTop: 0}}><span className="mbsc-txt-s">Logged-in as {this.props.user.customer.name}</span></div>
                            :
                            <React.Fragment>
                                <div className="mbsc-form-group-title">Existing Customers</div>
                                <div className="mbsc-padding" style={{paddingTop: 0}}>
                                    <span className="mbsc-txt-s"><a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/login') }}>Sign in</a> to select a previously used delivery address</span>
                                </div>
                                <div className="mbsc-form-group-title">New Customers</div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    : null}

                {bodyText ?
                    <React.Fragment>
                        <div className="mbsc-padding" style={{paddingTop: 0}}>
                            {this.props.showTick ? <span className="empty icon fas fa-check-circle" style={{color: "green"}}></span> : null } <span className="mbsc-txt-s">{bodyText}</span>
                        </div>
                    </React.Fragment>
                    : null}

                {deliveryAddressDropdown}

                {showDeliveryAddressLookup ?
                <React.Fragment>
                    <PlacesAutocomplete
                        value={this.props.user.checkout.delivery_address}
                        onChange={this.onDeliveryAddressChange}
                        onSelect={this.onDeliveryAddressSelect}
                        onError={this.onDeliveryAddressError}
                        searchOptions={this.state.placesSearchOptions}
                        shouldFetchSuggestions={this.props.user.checkout.delivery_address.length > 3}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <React.Fragment>
                            <mobiscroll.Input 
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                labelStyle="stacked" 
                                placeholder="Start typing your address here" 
                                type="text" 
                                name="searchDeliveryAddress">{showDeliveryAddressDropdown ? "New delivery address" : "Delivery address"}</mobiscroll.Input>
                            <div className="autocomplete-dropdown-container">
                            {loading && <div className="suggestion-item">Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active ? 'suggestion-item suggestion-item-active': 'suggestion-item';
                                //logger(suggestion);
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                            </div>
                        </React.Fragment>
                        )}
                    </PlacesAutocomplete>
                    
                    {showDeliveryAddressManualEntry ?
                    <React.Fragment>
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.delivery_address1} onChange={this.onDeliveryAddressManualChange} name="deliveryAddress1" data-fieldname="delivery_address1" autoComplete={this.props.user.auth.isKiosk || this.props.user.auth.isStaff ? "off" : "address-line1"}>{this.props.merchant.locale.labels.address1}</mobiscroll.Input>
                        {(this.props.merchant.locale.labels.hasOwnProperty('address2') && this.props.merchant.locale.labels.address2 !== '') ?
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.delivery_address2} onChange={this.onDeliveryAddressManualChange} name="deliveryAddress2" data-fieldname="delivery_address2" autoComplete={this.props.user.auth.isKiosk || this.props.user.auth.isStaff ? "off" : "address-line2"}>{this.props.merchant.locale.labels.address2}</mobiscroll.Input>
                        : null}
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.delivery_city} onChange={this.onDeliveryAddressManualChange} name="deliveryCity" data-fieldname="delivery_city" autoComplete={this.props.user.auth.isKiosk || this.props.user.auth.isStaff ? "off" : "address-level2"}>{this.props.merchant.locale.labels.city_suburb}</mobiscroll.Input>
                        {(this.props.merchant.locale.labels.hasOwnProperty('state') && this.props.merchant.locale.labels.state !== '') ?
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.props.user.checkout.delivery_state} onChange={this.onDeliveryAddressManualChange} name="deliveryState" data-fieldname="delivery_state" autoComplete={this.props.user.auth.isKiosk || this.props.user.auth.isStaff ? "off" : "address-level1"}>{this.props.merchant.locale.labels.state}</mobiscroll.Input>
                        : null}
                        <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.props.user.checkout.delivery_postcode} onChange={this.onDeliveryAddressManualChange} name="deliveryPostcode" data-fieldname="delivery_postcode" autoComplete={this.props.user.auth.isKiosk || this.props.user.auth.isStaff ? "off" : "postal-code"}>{this.props.merchant.locale.labels.postcode}</mobiscroll.Input>
                        {this.props.showValidateButton ?
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button color="success" style={{color: '#fff'}} onClick={() => { this.validateAddress(); }}>Validate Address</mobiscroll.Button>
                        </div> : null}
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="mbsc-padding" style={{paddingTop: "0.5em", paddingBottom: "0"}}><span className="mbsc-txt-s" style={{textTransform: "none"}}><a href="#" onClick={(e) => { e.preventDefault(); this.updateDeliveryAddressManual(true); }}>Enter address manually instead</a></span></div>
                    </React.Fragment>
                    }
                </React.Fragment>
                : null }
            </mobiscroll.FormGroup> 
        )
    }

}

const mapStateToProps = state => {
    return {
        merchant: state.menu.merchant,
        fulfilmentType: state.menu.fulfilmentType,
        fulfilmentOptions: state.menu.fulfilmentOptions,
        cart: state.cart,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout)),
        updateStateWithCustomer: (customer) => dispatch(actions.setCustomerAction(customer, null, null))
    }
}

//export default withRouter(DeliveryAddress);
export default withRouter( connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress) );
