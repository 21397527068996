import * as actionTypes from './actionTypes';

export const setSlugAction = ( slug ) => {
    return {
        type: actionTypes.SET_SLUG,
        payload: slug
    };
};

export const setMerchantAction = ( merchant ) => {
    return {
        type: actionTypes.SET_MERCHANT,
        payload: merchant
    };
};

export const setMenuItemsAction = ( menuItems ) => {
    return {
        type: actionTypes.SET_MENU_ITEMS,
        payload: menuItems
    };
};

export const setFulfilmentTypeAction = ( fulfilmentType ) => {
    /*
    return (dispatch) => {
        // async code, lecture 316
        ...
        dispatch(function());
    }
    */
    return {
        type: actionTypes.SET_FULFILMENT_TYPE,
        payload: fulfilmentType
    };
};

export const setFulfilmentOptionsAction = ( fulfilmentOptions ) => {
    /*
    return (dispatch) => {
        // async code, lecture 316
        ...
        dispatch(function());
    }
    */
    return {
        type: actionTypes.SET_FULFILMENT_OPTIONS,
        payload: fulfilmentOptions
    };
};

export const setOrderAction = ( order ) => {
    return {
        type: actionTypes.SET_ORDER,
        payload: order
    };
};
