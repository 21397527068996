import mobiscroll from "@mobiscroll/react";

import { mpoSentry } from './Sentry';
import {isAppModeKiosk, isAppModePersonal, isAppModePos, isCordova, openWindow} from '../shared/utility';
import React from "react";

const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';

export const mpoAppStore = {

    getAppDownloadJsx: () => {
        if (isAppModePos()) {
            return null;
        }
        if (isAppModeKiosk()) {
            if (process.env.REACT_APP_ITUNES_APP_URL === "" || process.env.REACT_APP_GOOGLE_APP_URL === "") {
                return null;
            }
            return <div className="mbsc-padding" style={{textAlign: "center"}}>
                <h3>Download our App</h3>
                <img alt="Apple App Store"
                     src="./img/appstores/Download_on_the_App_Store_Badge_US-UK_135x40.png" />
                <img alt="Google Play Store"
                     src="./img/appstores/en_app_rgb_wo_45.png" />
            </div>
        }
        const appDownloadInfo = mpoAppStore.getAppDownloadInfo();
        let downloadAppJsx = null;
        if (appDownloadInfo !== null && appDownloadInfo.downloadAppUrl !== "") {
            downloadAppJsx = <div className="mbsc-padding">
                <mobiscroll.Button block={true} data-icon={appDownloadInfo.downloadAppIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.downloadApp(appDownloadInfo.downloadAppUrl); }}>{appDownloadInfo.downloadAppText}</mobiscroll.Button>
            </div>
        } else if (!isCordova()) {
            downloadAppJsx = <div className="mbsc-padding" style={{textAlign: "center"}}>
                {process.env.REACT_APP_ITUNES_APP_URL !== "" ?
                    <a href={process.env.REACT_APP_ITUNES_APP_URL} target="_blank">
                        <img alt="Apple App Store"
                             src="./img/appstores/Download_on_the_App_Store_Badge_US-UK_135x40.png" /></a>
                    : null}
                {process.env.REACT_APP_GOOGLE_APP_URL !== "" ?
                    <a href={process.env.REACT_APP_GOOGLE_APP_URL} target="_blank">
                        <img alt="Google Play Store"
                             src="./img/appstores/en_app_rgb_wo_45.png" /></a>
                    : null}
            </div>
        }
        return downloadAppJsx;
    },

    getAppDownloadInfo: () => {
        let downloadAppUrl = "";
        let downloadAppIcon = "empty icon fas ";
        const downloadAppText = "Download our App";
        if (!isCordova()) {
            if (mobiscroll.platform.name === 'ios') {
                downloadAppUrl = process.env.REACT_APP_ITUNES_APP_URL;
                downloadAppIcon += "fa-apple";
            } else if (mobiscroll.platform.name === 'android') {
                downloadAppUrl = process.env.REACT_APP_GOOGLE_APP_URL;
                downloadAppIcon += "fa-google-play";
            }
        }
        return {
            downloadAppUrl: downloadAppUrl,
            downloadAppIcon: downloadAppIcon,
            downloadAppText: downloadAppText
        };
    },

    inviteUserToDownloadApp: (msg, url) => {

        if (msg !== "" && url !== "") {
            mobiscroll.confirm({
                title: 'Download our App',
                message: msg,
                okText: 'Download',
                cancelText: 'No Thanks'
            }).then((res) => {
                if (res) {
                    mpoAppStore.downloadApp(url);
                    mpoSentry.addBreadcrumb('appstore','Accepted to download app','info');
                } else {
                    mpoSentry.addBreadcrumb('appstore','Declined to download app','info');
                }
            });
        }

    },

    downloadApp: (url) => {
        if (typeof window !== "undefined") {
            openWindow(url, '_system', '', isCustomApp);
        } else {
            mobiscroll.alert({
                title: 'App Download',
                message: 'Unable to open your App Store'
            });
        }
    },

    inviteUserToReviewApp: (msg, url) => {

        if (msg !== "" && url !== "") {
            mobiscroll.confirm({
                title: 'Review our App',
                message: msg,
                okText: 'Review',
                cancelText: 'No Thanks'
            }).then((res) => {
                if (res) {
                    mpoAppStore.reviewApp(url);
                    mpoSentry.addBreadcrumb('appstore','Accepted to review app','info');
                } else {
                    mpoSentry.addBreadcrumb('appstore','Declined to review app','info');
                }
            });
        }

    },

    reviewApp: (url) => {
        if (typeof window !== "undefined") {
            openWindow(url, '_system', '', isCustomApp);
        } else {
            mobiscroll.alert({
                title: 'App Review',
                message: 'Unable to open your App Store'
            });
        }
    }

}