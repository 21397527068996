
import {logger, isCordova} from '../shared/utility';
import mobiscroll from "@mobiscroll/react";

import { mpoSentry } from './Sentry';
import {mpoAppStore} from '../lib/AppStore';

export const mpoUpgrade = {

    processUpgradeResponse: function (response) {

        try {
            if (response.data.hasOwnProperty('Upgrade') && Object.keys(response.data.Upgrade).length > 0 &&
                (response.data.Upgrade.Available === 'true' || response.data.Upgrade.Available === true || parseInt(response.data.Upgrade.Available,1) === 1)) {

                logger(response.data.Upgrade);

                let message = 'Update required. Refresh the page to apply the update.';
                let duration = false; // Display time of the message in milliseconds. If false, the message will be persistent.

                let actionText = 'Refresh Now';
                let actionLink = response.data.Upgrade.Link; // app download link

                let actionIcon = 'empty icon fas fa-sync-alt';
                if (isCordova()) {
                    message = 'Update required. Download the latest version from your App Store.';
                    actionText = 'Update Now';
                    if (mobiscroll.platform.name === 'ios') {
                        actionIcon = 'empty icon fas fa-apple';
                    } else {
                        actionIcon = 'empty icon fas fa-google-play';
                    }
                    if (response.data.Upgrade.Required  === 'true' || response.data.Upgrade.Required  === true || parseInt(response.data.Upgrade.Required,1) === 1) {

                    } else {
                        // optional app update
                        message = 'Update available. Download the latest version from your App Store.';
                        duration = 10000;
                    }
                }

                mobiscroll.snackbar({
                    message: message,
                    color: 'success',
                    duration: duration,
                    button: {
                        text: actionText,
                        icon: actionIcon,
                        action: (p) => {
                            if (isCordova()) {
                                mpoAppStore.downloadApp(actionLink);
                            } else {
                                window.location.reload(true);
                            }
                        }
                    }
                });

            }
        } catch(error) {
            logger(error);
            mpoSentry.captureException(error);
        }
    }

}