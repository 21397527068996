import * as actionTypes from './actionTypes';

export const setLocationGroupAction = ( merchants ) => {
    return {
        type: actionTypes.SET_LOCATION_GROUP,
        payload: merchants
    };
};

export const setMerchantsAction = ( merchants ) => {
    return {
        type: actionTypes.SET_MERCHANTS,
        payload: merchants
    };
};

export const setMerchantsNearbyAction = ( merchants ) => {
    return {
        type: actionTypes.SET_MERCHANTS_NEARBY,
        payload: merchants
    };
};

export const setMerchantsSearchAction = ( merchants ) => {
    return {
        type: actionTypes.SET_MERCHANTS_SEARCH,
        payload: merchants
    };
};

export const setMerchantsFaveAction = ( merchants ) => {
    return {
        type: actionTypes.SET_MERCHANTS_FAVE,
        payload: merchants
    };
};

export const setMerchantsHistoryAction = ( merchants ) => {
    return {
        type: actionTypes.SET_MERCHANTS_HISTORY,
        payload: merchants
    };
};

export const setMerchantTypesAction = ( merchant_types ) => {
    return {
        type: actionTypes.SET_MERCHANT_TYPES,
        payload: merchant_types
    };
};

export const setMerchantGroupsAction = ( merchant_groups ) => {
    return {
        type: actionTypes.SET_MERCHANT_GROUPS,
        payload: merchant_groups
    };
};

export const setLocationSettingsAction = ( settings ) => {
    return {
        type: actionTypes.SET_LOCATION_SETTINGS,
        payload: settings
    };
};
