import {logger} from "../../shared/utility";
import axiosInstance from "../../components/axios";
import mobiscroll from "@mobiscroll/react";
import {mpoSentry} from "../Sentry";

export const mpoPrintQueue = {

    addToQueue: function(pos_guid, order_id, callback) {
        logger('mpoPrintQueue.addToQueue called');
        const data = {
            RequestAction: 'PrintQueue',
            sub_action: 'add',
            pos_guid: pos_guid,
            order_id: order_id
        };
        //attachDeviceInfoToData(data);

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                let success = false;
                if (response.data.ResponseCode === "SUCCESS") {
                    success = true;
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], 'warning');
                }
                if (callback !== undefined && callback !== null && typeof(callback) === 'function') {
                    callback(success, response.data);
                }
            })
            .catch(error => {
                logger(error);
                mobiscroll.toast({message: 'Error PQ1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });
    },

}