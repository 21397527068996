import React, { Component } from 'react';
import { connect } from 'react-redux';
import {attachDeviceInfoToData, isAppModePersonal, isCordova} from '../../shared/utility';
import mobiscroll from "@mobiscroll/react";

import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import axiosInstance from '../../components/axios';
import { validateEmail, validateMobile } from '../../shared/validate'
import { mpoOneSignal } from '../../lib/OneSignal';
import {Helmet} from "react-helmet";

class AccountForgot extends Component {

    constructor(props) {
        super(props);
        //console.log(props);

        const isMobile = (this.props.hasOwnProperty('location') && this.props.location !== undefined &&
            this.props.location.hasOwnProperty('state') && this.props.location.state !== undefined &&
            this.props.location.state.hasOwnProperty('fromForgotPin') && this.props.location.state.fromForgotPin === true);

        this.state = {
            isEmail: !isMobile,
            isMobile: isMobile,
            btnText: isMobile ? "Reset PIN" : "Reset password",
            mobileBtnText: isMobile ? "Reset your password" : "Reset your PIN",
            email: '', //this.props.user.customer.email,
            emailValid: true,
            emailError: '',
            mobile: '', //this.props.user.customer.mobile,
            mobileValid: true,
            mobileError: '',
            state: '',
            stateValid: true,
            stateError: '',
            submitted: false
        };
    }

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','Forgot','info');
    }

    onEmailChange = (event) => {
        const invalid = validateEmail(event.target.value) ? false : 'Enter a valid email address';
        //console.log(invalid);
        this.setState({
            email: event.target.value,
            emailValid: !this.state.submitted || !invalid,
            emailError: invalid || ''
        });
    }
    
    onMobileChange = (event, inst) => {
        const invalid = event.valueText === '' ? 'This field is required' : false; 
        this.setState({
            mobile: event.valueText,
            mobileValid: !this.state.submitted || !invalid,
            mobileError: invalid || ''
        });
    }

    signUp = (event) => {
        const isMobile = this.state.isMobile;
        event.preventDefault();
        this.setState({
            submitted: false,
            btnText: isMobile ? "Reset PIN" : "Reset password",
            mobileBtnText: isMobile ? "Reset your password" : "Reset your PIN",
            isEmail: !isMobile,
            isMobile: isMobile,
            email: this.props.user.customer.email,
            emailValid: true,
            emailError: '',
            mobile: this.props.user.customer.mobile,
            mobileValid: true,
            mobileError: '',
            state: this.props.user.customer.timezone_state,
            stateValid: true,
            stateError: '',
        });
    }

    mobileReset = (event) => {
        const isMobile = !this.state.isMobile;
        event.preventDefault();
        this.setState({
            btnText: isMobile ? "Reset PIN" : "Reset password",
            mobileBtnText: isMobile ? "Reset your password" : "Reset your PIN",
            isMobile: isMobile
        });
    }

    submit = (event) => {
        event.preventDefault();
        this.sendToServer();
    }

    sendToServer = () => {

        const data = {
            RequestAction: 'ForgotLogin',
            login_type: this.state.isMobile ? 'mobile' : 'email',
        };

        if (this.state.isMobile) {
            data.mobile = this.state.mobile;
        } else {
            data.email = this.state.email;
        }

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }
        mpoSentry.addBreadcrumb('action',data.RequestAction,'info');

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "SUCCESS") {
                if (this.state.isMobile) {
                    mobiscroll.toast({
                        message: "Check your phone for an sms from us.",
                        color: 'success'
                    });
                } else {
                    mobiscroll.toast({
                        message: "Check your email account for a password reset link.",
                        color: 'success'
                    });
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], 'warning');
            }
            this.setState({
                email: '',
                mobile: ''
            });
        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error, please try again', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_TITLE} Account`}</title>
                </Helmet>
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked"
                    onSubmit={this.submit}
                    novalidate
                >
                    <mobiscroll.FormGroup inset>
                        <div className="mbsc-form-group-title">{this.state.btnText}</div>
                        <React.Fragment>
                        {this.state.isMobile ?
                        <React.Fragment>
                            {/* reset pin form */}
                            <mobiscroll.Numpad
                                fill="ltr"
                                template="dddd ddd ddd"
                                allowLeadingZero={true}
                                placeholder=""
                                showOnFocus={true}
                                validate={validateMobile}
                                onSet={this.onMobileChange}>
                                <mobiscroll.Input 
                                    labelStyle="stacked" 
                                    //errorMessage={this.state.mobileError}
                                    //valid={!this.state.mobileValid}
                                    value={this.state.mobile}>Mobile</mobiscroll.Input>
                            </mobiscroll.Numpad>
                        </React.Fragment>
                        : 
                        <React.Fragment>
                            {/* reset email form */}
                            <mobiscroll.Input
                                labelStyle="stacked"
                                type="email" 
                                value={this.state.email} 
                                onChange={this.onEmailChange} 
                                valid={this.state.emailValid} 
                                errorMessage={this.state.emailError}>Email</mobiscroll.Input>
                        </React.Fragment>
                        }
                        </React.Fragment>
                    </mobiscroll.FormGroup>

                    <mobiscroll.FormGroup inset className="mbsc-padding">
                        <mobiscroll.Button type="submit" block={true} color="success">{this.state.btnText}</mobiscroll.Button>
                    </mobiscroll.FormGroup>

                    <mobiscroll.FormGroup inset className="mbsc-padding mbsc-align-center">
                        {isAppModePersonal() ?
                        <a href="#" onClick={this.mobileReset}>{this.state.mobileBtnText}</a>
                        : null }
                        <br/><br/>
                        <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/login') }}>Back to Sign in</a>
                    </mobiscroll.FormGroup>

                </mobiscroll.Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            //console.log(ownProps);
            let redirect = '/';
            if (ownProps !== undefined && ownProps !== null &&
                ownProps.hasOwnProperty('location') && ownProps.location !== undefined &&
                ownProps.location.hasOwnProperty('state') && ownProps.location.state !== undefined) {
                if (ownProps.location.state.hasOwnProperty('next')) {
                    redirect = ownProps.location.state.next;
                } else if (ownProps.location.state.hasOwnProperty('from') && ownProps.location.state.from !== '/login') {
                    redirect = ownProps.location.state.from;
                }
            }
            //console.log('redirect', redirect);
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountForgot);
