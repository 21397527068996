
import { logger, attachDeviceInfoToData, isCordova } from '../shared/utility';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../components/axios';
import * as Sentry from "@sentry/react";

import { mpoSentry } from './Sentry';
import {mpoSession} from "./Session";
import {mpoOneSignal} from "./OneSignal";

export const mpoThirdPartyUser = {
    _app_props: null,

    login: (appProps, sessionId, userId, userToken, debug) => {
        debug = debug || false;
        return new Promise((resolve, reject) => {
            if (appProps !== null) {
                if (debug) logger(appProps);
                mpoThirdPartyUser._app_props = appProps;
            }
            if (sessionId) {
                mpoSession.setSessionCookie(sessionId)
                    .then((res) => {
                        if (debug) logger(res);
                        resolve(res)
                    }, (err) => {
                        if (debug) logger(err);
                        reject(err)
                    });
            } else if (userId && userToken) {
                mpoThirdPartyUser.loginThirdPartyUser(userId, userToken, debug)
                    .then((res) => {
                        if (debug) logger(res);
                        resolve(res)
                    }, (err) => {
                        if (debug) logger(err);
                        reject(err)
                    });
            } else {
                reject("TPU: Nothing to do");
            }
        });
    },

    loginThirdPartyUser: (userId, userToken, debug) => {

        return new Promise((resolve, reject) => {
            const data = {
                RequestAction: 'ThirdPartyUser',
                method: 'login',
                userid: userId,
                usertoken: userToken
            };
            mpoSentry.addBreadcrumb('action', data.RequestAction, 'info');

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            axiosInstance.post(null, data)
                .then(response => {
                    //logger(response);
                    if (response.data.ResponseCode === "SUCCESS") {
                        Sentry.configureScope((scope) => {
                            scope.setUser({
                                "id": response.data.Response.customer.id,
                                "email": response.data.Response.customer.email
                            });
                        });
                        mpoSentry.addBreadcrumb('auth', 'Authenticated user ' + response.data.Response.customer.email, 'info');
                        resolve(response.data.Response.customer);
                    } else {
                        mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                        mpoSentry.captureMessage(response.data.Response[0], 'warning');
                        reject("LTPU: "+response.data.Response[0]);
                    }

                })
                .catch(error => {
                    //logger(error);
                    mobiscroll.toast({message: 'Error TP1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                    mpoSentry.captureException(error);
                    reject("LTPU: Error");
                });
        });
    }


}