import {logger, isCordova} from '../shared/utility';
import mobiscroll from "@mobiscroll/react";
import { mpoSentry } from './Sentry';
import queryString from "query-string";


// if (!isCordova()) {
//     import ('firebase/app').then((i) => i.firebase);
// }

// todo: change to react functional component so can access redux props
export const mpoFirebase = {
    _initted: false,
    _init_attempts: 0,
    _app_props: null,
    _firebase_app: null,
    _firebase_analytics: null,
    _deeplink: null,

    Init: function (appProps = null) {
        logger('mpoFirebase.Init called (props '+(appProps === null ? 'null' : 'set')+')');
        mpoFirebase._init_attempts++;

        if (mpoFirebase._initted) {
            return;
        }

        if (isCordova()) {
            /*eslint-disable no-undef*/
            if (cordova.plugins && cordova.plugins.firebase) {
                try {
                    logger('Firebase Cordova init');
                    mpoFirebase._initted = true;
                    mpoFirebase._app_props = appProps;

                    // dynamic links
                    cordova.plugins.firebase.dynamiclinks.onDynamicLink((data) => {
                            logger("Firebase onDynamicLink called");
                            //var props = mpoFirebase._app_props === null ? "propsnull" : "propsset";
                            //mobiscroll.toast({message: "onDynamicLink: "+props+"|"+JSON.stringify(data), color: 'info'});
                            mpoFirebase.HandleDynamicLinkData(data);
                        }
                    );

                    cordova.plugins.firebase.dynamiclinks.getDynamicLink().then((data) => {
                        if (data) {
                            // Read dynamic link data on app start
                            logger("Firebase getDynamicLink called");
                            mpoFirebase.HandleDynamicLinkData(data);
                        }
                    });

                    // firebase analytics
                    // if (cordova.plugins.firebase.analytics) {
                    //     cordova.plugins.firebase.analytics.setDefaultEventParameters({
                    //         environment: process.env.NODE_ENV,
                    //         release: process.env.REACT_APP_APP_VERSION,
                    //         appId: process.env.REACT_APP_APP_ID,
                    //         appVersion: process.env.REACT_APP_APP_VERSION,
                    //         apiVersion: process.env.REACT_APP_API_VERSION
                    //     });
                    // }

                } catch (error) {
                    logger('Firebase Cordova init error');
                    if (mpoFirebase._init_attempts < 3) {
                        setTimeout(mpoFirebase.Init, 1000, appProps);
                    } else {
                        mpoSentry.captureException(error);
                    }
                }
                /*eslint-enable no-undef*/
            } else if (mpoFirebase._init_attempts < 5) {
                // retry in 2 seconds
                setTimeout(mpoFirebase.Init, 2000, appProps);
            }

        /*eslint-disable no-undef*/
        } else {

            //logger('Firebase Browser init');

            //firebase
            if (process.env.REACT_APP_FIREBASE_ENABLED !== undefined && process.env.REACT_APP_FIREBASE_ENABLED === 'true' &&
                process.env.REACT_APP_FIREBASE_APIKEY !== undefined && process.env.REACT_APP_FIREBASE_APIKEY !== '') {
                // const firebaseConfig = {
                //     apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
                //     authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
                //     databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
                //     projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
                //     storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
                //     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
                //     appId: process.env.REACT_APP_FIREBASE_APPID,
                //     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
                // };
                // mpoFirebase._firebase_app = firebase.initializeApp(firebaseConfig);
                // mpoFirebase._firebase_analytics = firebase.getAnalytics(mpoFirebase._firebase_app);
            }

            // google analytics
            if (typeof gtag === "function") {
                // gtag('set', {
                //     environment: process.env.NODE_ENV,
                //     release: process.env.REACT_APP_APP_VERSION,
                //     appId: process.env.REACT_APP_APP_ID,
                //     appVersion: process.env.REACT_APP_APP_VERSION,
                //     apiVersion: process.env.REACT_APP_API_VERSION
                // });
            }

        }
        /*eslint-enable no-undef*/
    },

    SetProps: function (appProps = null) {
        //logger('Firebase SetProps called (props '+(appProps === null ? 'null' : 'set')+')');
        if (appProps !== null) {
            if (!mpoFirebase._initted) {
                logger('Firebase SetProps init Firebase');
                mpoFirebase.Init(appProps);
            } else {
                mpoFirebase._app_props = appProps;
                if (mpoFirebase._deeplink !== null) {
                    // been awaiting props so we can go to deeplink
                    logger('Firebase SetProps open deeplink');
                    let deeplink = mpoFirebase._deeplink;
                    mpoFirebase._deeplink = null;
                    mpoFirebase.GotoDeeplink(deeplink);
                }
            }
        }
    },

    HandleDynamicLinkData: function (data) {
        logger('Firebase HandleDynamicLinkData');
        logger(JSON.stringify(data));
        if (data.hasOwnProperty('deepLink')) {
            if (mpoFirebase._app_props === null) {
                // store until we have props
                logger('Firebase store deeplink and wait for props');
                mpoFirebase._deeplink = data.deepLink;
                //mobiscroll.toast({message: "deeplink set: "+mpoFirebase._deeplink, color: 'info'});
            } else {
                logger('Firebase go straight to deeplink');
                mpoFirebase.GotoDeeplink(data.deepLink);
                // setTimeout(() => {
                //     mpoFirebase.GotoDeeplink(data.deepLink);
                // }, 0);
            }
        } else {
            logger('Firebase no deeplink');
            mobiscroll.toast({message: "no deeplink found", color: 'danger'});
        }
    },

    GotoDeeplink: function(deeplink) {
        logger('Firebase GotoDeeplink called: '+deeplink);
        //mobiscroll.toast({message: "GotoDeeplink: "+deeplink, color: 'info'});
        if (mpoFirebase._app_props !== undefined && mpoFirebase._app_props !== null && mpoFirebase._app_props.hasOwnProperty('history') && deeplink !== null) {
            logger('Firebase GotoDeeplink has props');
            const query = deeplink.substring(deeplink.lastIndexOf("?"));
            const params = queryString.parse(query);
            const menuName = params.menu ? params.menu : null;
            const otherParamAction = params.act ? params.act : null;

            let pathName = '';

            if (menuName) {
                pathName = "/menu/"+menuName;
            } else if (otherParamAction) {
                switch (otherParamAction) {
                    case 'acct':
                    case 'account':
                        pathName = '/account'
                        break;
                    case 'hist':
                    case 'history':
                    case 'orders':
                        pathName = '/account/orders'
                        break;
                    case 'rewards':
                        pathName = '/account/rewards'
                        break;
                    case 'cards':
                    case 'billing':
                    case 'managecard':
                    case 'withdraw':
                    case 'crypto':
                    case 'applepay':
                    case 'googlepay':
                        pathName = '/account/managecard'
                        break;
                    case 'addcard':
                        pathName = '/account/addcard'
                        break;
                    case 'topup':
                        pathName = '/account/topup'
                        break;
                    case 'feedback':
                        pathName = '/account/feedback'
                        break;
                    case 'reset':
                        pathName = '/account/reset'
                        break;
                    default:
                }
            }

            if (pathName !== '') {
                logger('Firebase GotoDeeplink '+mpoFirebase._app_props.location.pathname+' --> '+pathName);
                logger(JSON.stringify(mpoFirebase._app_props));
                let location = {
                    pathname: pathName,
                    search: query,
                    state: {fromDeeplink: true}
                }
                if (mpoFirebase._app_props.location.pathname === pathName) {
                    // screen can go blank whilst window reloads
                    mobiscroll.notification.dismiss();
                    mobiscroll.toast({message: "Verifying link...", duration: 2000, display: 'center', color: 'info'});
                    mpoFirebase._app_props.history.replace(location);
                    logger('Firebase GotoDeeplink history replace');
                    if (typeof window !== "undefined") {
                        window.location.reload(); // force re-render; todo: could update redux props directly instead
                    }
                } else {
                    mpoFirebase._app_props.history.push(location);
                    logger('Firebase GotoDeeplink history push');
                }
            }
        }
    },

    LogEvent: function(event, data) {
        /*eslint-disable no-undef*/
        try {
            if (isCordova() && cordova.plugins.firebase.analytics) {
                //logger("Firebase LogEvent");
                //cordova.plugins.firebase.analytics.logEvent("my_event", {param1: "value1"});
                cordova.plugins.firebase.analytics.logEvent(event, data);
            } else if (!isCordova() && typeof gtag === "function") {
                //logger("GA LogEvent");
                gtag('event', event, data);
            }
        } catch (e) {}
        /*eslint-enable no-undef*/
    },

    SetUserId: function(id) {
        /*eslint-disable no-undef*/
        try {
            if (isCordova() && cordova.plugins.firebase.analytics) {
                //cordova.plugins.firebase.analytics.setUserId("12345");
                cordova.plugins.firebase.analytics.setUserId(id);
            }
        } catch (e) {}
        /*eslint-enable no-undef*/
    },

    SetUserProperty: function(key, value) {
        /*eslint-disable no-undef*/
        try {
            if (isCordova() && cordova.plugins.firebase.analytics) {
                //cordova.plugins.firebase.analytics.setUserProperty("name1", "value1");
                cordova.plugins.firebase.analytics.setUserProperty(key, value);
            }
        } catch (e) {}
        /*eslint-enable no-undef*/
    },

    SetScreenName: function(id) {
        /*eslint-disable no-undef*/
        try {
            if (isCordova() && cordova.plugins.firebase.analytics) {
                //cordova.plugins.firebase.analytics.setCurrentScreen("User profile");
                cordova.plugins.firebase.analytics.setCurrentScreen(id);
            }
        } catch (e) {}
        /*eslint-enable no-undef*/
    },

    EnableAnalytics: function(enable) {
        /*eslint-disable no-undef*/
        try {
            if (isCordova() && cordova.plugins.firebase.analytics) {
                //cordova.plugins.firebase.analytics.setEnabled(false);
                cordova.plugins.firebase.analytics.setEnabled(enable);
            }
        } catch (e) {}
        /*eslint-enable no-undef*/
    },

}