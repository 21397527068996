import React, { Component } from 'react';
import ContentLoader from "react-content-loader"

class CategoryListItem extends Component {

    render = () => {
        if (this.props.item.hasOwnProperty('product_placeholder')) {
            return (
                <li data-component="CategoryListItem">
                    <ContentLoader 
                        key={this.props.item.key}
                        height={80}
                        width={400}
                        speed={2}
                        primaryColor="#f3f3f3"
                        secondaryColor="#a8a8a8"
                    >
                        <rect x="83" y="17" rx="5" ry="5" width="240" height="11" /> 
                        <rect x="84" y="45" rx="5" ry="5" width="207" height="9" /> 
                        <rect x="10" y="14" rx="0" ry="0" width="46" height="45" />
                    </ContentLoader>
                </li>
            )
        }

        if (this.props.item.header) {
            //logger(this.props.item);
            if (this.props.item.hasOwnProperty('children') && this.props.item.children.length > 0) {

                // iterate children and hide category if no children visible
                //logger(this.props.item.children);
                let numVisibleChildren = this.props.item.children.length;
                for (var i = 0; i < this.props.item.children.length; i++) {
                    if (this.props.item.children[i].hasOwnProperty('is_visible') && this.props.item.children[i].is_visible === false) {
                        numVisibleChildren--;
                    }
                }
                //logger(numVisibleChildren+'/'+this.props.item.children.length+' visible children')
                const lihStyle = {};
                if (numVisibleChildren === 0 || (this.props.item.hasOwnProperty('is_visible') && this.props.item.is_visible === false)) {
                    lihStyle.display = 'none';
                    //logger(lihStyle);
                }

                let imgUrl = null; //this.props.item.hasOwnProperty('img_url') && this.props.item.img_url ? this.props.item.img_url : null;
                const groupHeadClassName = imgUrl ? "mpo-product-group-head-with-image" : "mpo-product-group-head-no-image";
                const defaultCategorySquare = process.env.REACT_APP_DEFAULT_PRODUCT_SQUARE;
                return (<li key={this.props.item.id} data-selected={this.props.item.hasOwnProperty('default_selection') && this.props.item.default_selection} data-id={this.props.item.id} data-component="CategoryListItem" data-itemid="0" data-catid={this.props.item.id} className={groupHeadClassName} style={lihStyle}>
                    <span className="mbsc-pull-left">{imgUrl ? <img src={imgUrl} alt="img" onError={(e)=>{e.target.onerror = null; e.target.src=defaultCategorySquare}} /> : ''}</span>
                    <div className="mbsc-lv-arr mbsc-lv-ic mbsc-ic mbsc-ic-arrow-right4" style={{right: 0}}></div>
                    <h4 className="mpo-product-group-head">{this.props.item.name}</h4>
                    {this.props.item.desc ? <p className="mpo-product-group-head-desc">{this.props.item.desc}</p> : ''}
                    {/*this.props.children*/}
                </li>);
            } else {
                return (<li key={this.props.item.id} data-id={this.props.item.id} data-component="CategoryListItem" data-itemid="0" data-catid={this.props.item.id} className="mpo-product-group-head-no-image">
                        <h4 className="mpo-product-group-head">{this.props.item.name}</h4>
                        {this.props.item.desc ? <p className="mpo-product-group-head-desc">{this.props.item.desc}</p> : ''}
                    </li>
                );
            }
        }

        return null;
    }
}

export default CategoryListItem;