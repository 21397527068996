
export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}

export const validateEmail = (value) => {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));
}

export const validatePassword = (value) => {
    return true;
}

export const validateMobile = (e, inst) => {
    // mobiscroll numpad component
    let disabled = [];
    if (e.values.length === 0) {
        disabled = [1,2,3,4,5,6,7,8,9];
    } else if (e.values.length === 1) {
        disabled = [0,1,2,3,5,6,7,8,9];
    }

    return {
        disabled: disabled,
        invalid: e.values.length > 0 && e.values.length < 10
    };
};

export const validateAccountPin = (e, inst) => {
    // mobiscroll numpad component
    return {
        disabled: [],
        invalid: e.values.length < 4
    };
};

export const validateCardNumber = (e, inst) => {
    // mobiscroll numpad component
    return {
        disabled: [],
        invalid: e.values.length > 0 && e.values.length < 15
    };
};

export const formatCardNumber = (numbers, variables, inst) => {
    // Specify how the value will be formatted 5,5,5 for amex, 4,4,4,4 for others
    //return (numbers.length > 2 ? '(' : '') + numbers.slice(0, 3).join('') + (numbers.length > 2 ? ') ' : '') + numbers.slice(3, 6).join('') + (numbers.length > 5 ? '-' : '') + numbers.slice(6, 10).join('');
}

export const validateCardExpiry = (e, inst) => {
    // mobiscroll numpad component
    let disabled = [];
    //console.log(e.values);
    if (e.values.length === 0) {
        disabled = [2,3,4,5,6,7,8,9];
    } else if (e.values.length === 1) {
        if (e.values[0] === 0) {
            disabled = [0];
        } else {
            disabled = [3,4,5,6,7,8,9];
        }
    } else if (e.values.length === 2) {
        disabled = [0,1,3,4,5,6,7,8,9];
    } else if (e.values.length === 3) {
        disabled = [1,2,3,4,5,6,7,8,9];
    } else if (e.values.length === 4) {
        disabled = [0];
    }

    return {
        disabled: disabled,
        invalid: e.values.length > 0 && e.values.length < 6
    };
};

export const validateCardCSC = (e, inst) => {
    // mobiscroll numpad component
    return {
        disabled: [],
        invalid: e.values.length > 0 && e.values.length < 3
    };
};
