import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import mobiscroll from "@mobiscroll/react";

import { mpoSentry } from '../lib/Sentry';
import {isAppModeKiosk, isCordova, openWindow} from "../shared/utility";
import {mpoAppStore} from "../lib/AppStore";
import {mpoShareThis} from "../lib/ShareThis";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';
const termsUrl = process.env.REACT_APP_APP_TERMS_URL !== undefined && process.env.REACT_APP_APP_TERMS_URL !== null && process.env.REACT_APP_APP_TERMS_URL !== "" ? process.env.REACT_APP_APP_TERMS_URL : null;
const termsUrlText = process.env.REACT_APP_TERMS_URL_TEXT !== undefined && process.env.REACT_APP_TERMS_URL_TEXT !== null && process.env.REACT_APP_TERMS_URL_TEXT !== "" ? process.env.REACT_APP_TERMS_URL_TEXT : "terms of use";
const privacyUrl = process.env.REACT_APP_APP_PRIVACY_URL !== undefined && process.env.REACT_APP_APP_PRIVACY_URL !== null && process.env.REACT_APP_APP_PRIVACY_URL !== "" ? process.env.REACT_APP_APP_PRIVACY_URL : null;

class About extends Component {

    componentDidMount = () => {
        mpoSentry.addBreadcrumb('nav','About','info');
    }

    render = () => {

        const downloadAppJsx = mpoAppStore.getAppDownloadJsx();
        const shareJsx = mpoShareThis.getShareThisJsx();

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`About ${process.env.REACT_APP_APP_TITLE}`}</title>
                </Helmet>
                <mobiscroll.Form className="mpo-form-width-md">
                    <div className="mbsc-empty">
                        <h3>About {process.env.REACT_APP_APP_TITLE}</h3>
                        {process.env.REACT_APP_APP_DESCRIPTION !== null && process.env.REACT_APP_APP_DESCRIPTION !== "" ? <p>{process.env.REACT_APP_APP_DESCRIPTION}</p> : null }

                        {isCordova() && process.env.REACT_APP_CUSTOM_WEBSITE !== undefined && process.env.REACT_APP_CUSTOM_WEBSITE !== null && process.env.REACT_APP_CUSTOM_WEBSITE !== "" ?
                            <p><a href="#" onClick={(e) => { e.preventDefault(); openWindow(process.env.REACT_APP_CUSTOM_WEBSITE, '_system', ''); }}>{process.env.REACT_APP_CUSTOM_WEBSITE}</a></p> : null }

                        {!isCordova() && process.env.REACT_APP_CUSTOM_WEBSITE !== undefined && process.env.REACT_APP_CUSTOM_WEBSITE !== null && process.env.REACT_APP_CUSTOM_WEBSITE !== "" ?
                            <p><a href={process.env.REACT_APP_CUSTOM_WEBSITE} target="_blank">{process.env.REACT_APP_CUSTOM_WEBSITE}</a></p> : null }

                        {isCordova() ?
                            <p>Secure online payments processing by
                                {process.env.REACT_APP_CUSTOM_PAYMENTS !== undefined && process.env.REACT_APP_CUSTOM_PAYMENTS !== null && process.env.REACT_APP_CUSTOM_PAYMENTS !== "" ?
                                    <span> {process.env.REACT_APP_CUSTOM_PAYMENTS}</span>
                                    :
                                    <span><br/><a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        openWindow("https://www.netpayments.com.au", '_system', '');
                                    }}>Net Payments</a></span>
                                }
                            </p>
                            :
                            <p>Secure online payments processing by
                                {process.env.REACT_APP_CUSTOM_PAYMENTS !== undefined && process.env.REACT_APP_CUSTOM_PAYMENTS !== null && process.env.REACT_APP_CUSTOM_PAYMENTS !== "" ?
                                    <span> {process.env.REACT_APP_CUSTOM_PAYMENTS}</span>
                                    :
                                    <span><br/><a href="https://www.netpayments.com.au" target="_blank">Net Payments</a></span>
                                }
                            </p>
                        }

                        {isCordova() ?
                            <p>Software by <a href="#" onClick={(e) => {
                                e.preventDefault();
                                openWindow("https://www.mypreorder.com.au", '_system', '');
                            }}>mypreorder online ordering and branded apps</a></p>
                            :
                            (
                                isAppModeKiosk() ? <p>Kiosk by mypreorder.com.au - online ordering, kiosks, branded apps</p> :
                                <p>Software by <a href="https://www.mypreorder.com.au" target="_blank">mypreorder online ordering and branded apps</a></p>
                            )
                        }

                        <p>Software Version: {process.env.REACT_APP_APP_CUSTOM_VERSION ? process.env.REACT_APP_APP_CUSTOM_VERSION : process.env.REACT_APP_APP_VERSION}{debugMode ? "-DEBUG" : null}</p>

                        {termsUrl === null || privacyUrl === null ?
                            <p>By using this {isCordova() ? "app" : "site"} you agree to our <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/terms');
                            }}>{termsUrlText}</a> and <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/privacy');
                            }}>privacy policy</a>.</p>
                            :
                            (isCordova() ?
                                <p>By using this app you agree to our <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openWindow(termsUrl, '_system', '');
                                }}>{termsUrlText}</a> and <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openWindow(privacyUrl, '_system', '');
                                }}>privacy policy</a>.</p>
                                :
                                <p>By using this site you agree to our <a href={termsUrl} target="_blank">{termsUrlText}</a> and <a href={privacyUrl} target="_blank">privacy policy</a>.</p>
                            )
                        }
                    </div>
                    {downloadAppJsx}
                    {shareJsx}
                </mobiscroll.Form>
            </div>
        );
    }
}

export default About;