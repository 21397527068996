import React, { Component } from 'react';
import mobiscroll from "@mobiscroll/react";
import ContentLoader from "react-content-loader"
import {nl2br} from "../../shared/utility";

class MerchantGroupCard extends Component {

    constructor (props) {
        super(props);
        this.state = {
            heroImage: true
        }
        this.handleChangeLocationGroup = this.handleChangeLocationGroup.bind(this);
    }

    handleChangeLocationGroup = () => {
        // console.log("StandingOrderProductItem.handleChangeLocationGroup");
        // console.log(this.props.item.code);
        if (this.props.handleChangeLocationGroup !== undefined && typeof(this.props.handleChangeLocationGroup) === 'function') {
            this.props.handleChangeLocationGroup(this.props.item.code);
        }
    }

    render = () => {
        //logger(this.props);

        if (this.props.item.hasOwnProperty('merchant_placeholder')) {
            return (
                <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-lg-4 mbsc-col-xl-4">
                    <mobiscroll.Card>
                        <mobiscroll.CardContent>
                            <ContentLoader 
                                height={160}
                                width={400}
                                speed={2}
                                primaryColor="#f3f3f3"
                                secondaryColor="#a8a8a8">
                                <rect x="70" y="15" rx="4" ry="4" width="117" height="6" /> 
                                <rect x="70" y="35" rx="3" ry="3" width="85" height="6" /> 
                                <rect x="0" y="80" rx="3" ry="3" width="350" height="6" /> 
                                <rect x="0" y="100" rx="3" ry="3" width="380" height="6" /> 
                                <rect x="0" y="120" rx="3" ry="3" width="201" height="6" /> 
                                <circle cx="30" cy="30" r="30" />
                            </ContentLoader>
                        </mobiscroll.CardContent>
                    </mobiscroll.Card>
                </div>
            )
        }

        const defaultLogoSquare = process.env.REACT_APP_DEFAULT_LOGO_SQUARE;
        const displayLogoSquare = process.env.REACT_APP_DISPLAY_LOGO_SQUARE === 'true';
        const heroImage = this.state.heroImage && this.props.item.hasOwnProperty('hero_img_url') && this.props.item.hero_img_url !== '' ? this.props.item.hero_img_url : '';
        const displayHeroImage = heroImage !== '';

        const isActive = parseInt(this.props.item.is_active, 10) === 1 && this.props.item.merchants.length > 0;
        const logoOpacity = isActive ? 1 : 0.5;

        return (
            <div className="mbsc-col-12 mbsc-col-xs-12 mbsc-col-sm-12 mbsc-col-md-6 mbsc-col-lg-4 mbsc-col-xl-4">
                {displayHeroImage ? 
                    <mobiscroll.Card style={{margin: "0.5em"}}>
                    <div className="md-card-overlay">
                        <mobiscroll.CardContent onClick={(e) => { e.preventDefault(); if (isActive) this.handleChangeLocationGroup() }}>
                            <img src={heroImage} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; this.setState({heroImage: false}) }} />
                            {displayLogoSquare ? <img className="mbsc-pull-left" style={{maxWidth: "80px", paddingRight: "1em"}} src={this.props.item.logo_square_url} alt="Logo" onError={(e)=>{e.target.onerror = null; e.target.src=defaultLogoSquare}} /> : null}
                            <mobiscroll.CardTitle className="md-card-hero-title">{this.props.item.group_title}</mobiscroll.CardTitle>
                            {this.props.item.group_subtitle ?
                                <mobiscroll.CardSubtitle className="md-card-hero-subtitle"><span dangerouslySetInnerHTML={{__html: nl2br(this.props.item.group_subtitle)}}></span></mobiscroll.CardSubtitle>
                                : null }
                        </mobiscroll.CardContent>
                        {/*
                        <mbsc-footer>
                            {isActive ?
                                <React.Fragment>
                                    {this.props.item.button_text ?
                                        <mobiscroll.Button className="mbsc-pull-right" color="success" style={{color: '#fff'}} onClick={(e) => { e.preventDefault(); this.handleChangeLocationGroup() }}>{this.props.item.button_text}</mobiscroll.Button>
                                        : null
                                    }
                                </React.Fragment>
                                :
                                <span className="mbsc-pull-right" style={{display:'inline-block',margin:'0.5em 0.25em',padding:'0.625em 1em',color:'#fff'}}>{this.props.item.inactive_text}</span>
                            }
                        </mbsc-footer>
                        */}
                    </div>
                    </mobiscroll.Card>
                :
                <mobiscroll.Card style={{margin: "0.5em"}}>
                    <mobiscroll.CardContent onClick={(e) => { e.preventDefault(); if (isActive) this.handleChangeLocationGroup() }}>
                    {displayLogoSquare ? 
                        <div>
                            <img className="mbsc-pull-left" style={{maxWidth: "80px", paddingRight: "1em", opacity: logoOpacity}} src={this.props.item.logo_square_url} alt="Logo" onError={(e)=>{e.target.onerror = null; e.target.src=defaultLogoSquare}} />
                        </div>
                    : null}
                    <mobiscroll.CardTitle>{this.props.item.group_title}</mobiscroll.CardTitle>
                    {this.props.item.group_subtitle ?
                        <mobiscroll.CardSubtitle><span dangerouslySetInnerHTML={{__html: nl2br(this.props.item.group_subtitle)}}></span></mobiscroll.CardSubtitle>
                        : null }
                    </mobiscroll.CardContent>
                    {/*
                    <mbsc-footer>
                        {isActive ?
                            <React.Fragment>
                                {this.props.item.button_text ?
                                    <mobiscroll.Button className="mbsc-pull-right" color="success" style={{color: '#fff'}} onClick={(e) => { e.preventDefault(); this.handleChangeLocationGroup() }}>{this.props.item.button_text}</mobiscroll.Button>
                                    : null
                                    }
                            </React.Fragment>
                            :
                            <span className="mbsc-pull-right" style={{display:'inline-block',margin:'0.5em 0.25em',padding:'0.625em 1em'}}>{this.props.item.inactive_text}</span>
                        }
                    </mbsc-footer>
                    */}
                </mobiscroll.Card>
            }
            </div>
        );
    }
}

export default MerchantGroupCard;